import {Machine, MachineUrl} from "@co-common-libs/resources";
import {identifierComparator, notNull} from "@co-common-libs/utils";
import {
  ColumnSpecifications,
  GenericTable,
  RowData,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  actions,
  getCustomerSettings,
  getExtendedCustomerSettings,
  getMachineArray,
  getMachineLookup,
  getTableSortingState,
} from "@co-frontend-libs/redux";
import {MachineCreateEditDialog} from "app-components";
import {getVerticalStackingFabstyle, useQueryParameter} from "app-utils";
import {ImportMachinesFab} from "feat-import-resources";
import CheckIcon from "mdi-react/CheckIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const TABLE_SORTING_IDENTIFIER = "MachineTable";

type MachineTableColumnID =
  | "active"
  | "c5_machine"
  | "canPull"
  | "name"
  | "selfPropelled"
  | "smallMachine";

type MachineTableFieldID =
  | "active"
  | "c5_machine"
  | "canPull"
  | "name"
  | "selfPropelled"
  | "smallMachine";

interface MachineTableDataType extends RowData<MachineTableFieldID, MachineUrl> {
  active: boolean;
  c5_machine: string;
  canPull: boolean;
  name: string;
  selfPropelled: boolean;
  smallMachine: boolean;
}

function renderActive(data: MachineTableDataType): React.JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.active ? <CheckIcon /> : <></>;
}

function renderSelfPropelledInternal(data: MachineTableDataType): React.JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.selfPropelled ? <CheckIcon /> : <></>;
}
function renderCanPull(data: MachineTableDataType): React.JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.canPull ? <CheckIcon /> : <></>;
}
function renderSmallMachine(data: MachineTableDataType): React.JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.smallMachine ? <CheckIcon /> : <></>;
}

function compareID(a: MachineTableDataType, b: MachineTableDataType): number {
  return identifierComparator(a.c5_machine, b.c5_machine);
}

function buildColumnSpecifications(
  onClick: ((machineURL: MachineUrl) => void) | undefined,
  machineLabelVariant: "MACHINE" | "VEHICLE",
): ColumnSpecifications<
  MachineTableFieldID,
  MachineTableColumnID,
  MachineUrl,
  MachineTableDataType
> {
  return {
    active: {
      field: "active",
      label: <FormattedMessage defaultMessage="Aktiv" />,
      onClick,
      render: renderActive,
    },
    c5_machine: {
      comparator: compareID,
      field: "c5_machine",
      label:
        machineLabelVariant === "MACHINE" ? (
          <FormattedMessage defaultMessage="Maskinenr." />
        ) : (
          <FormattedMessage defaultMessage="Køretøjsnr." />
        ),
      onClick,
    },
    canPull: {
      field: "canPull",
      label: <FormattedMessage defaultMessage="Trækker" />,
      onClick,
      render: renderCanPull,
    },
    name: {
      field: "name",
      label: <FormattedMessage defaultMessage="Navn" />,
      onClick,
    },
    selfPropelled: {
      field: "selfPropelled",
      label: <FormattedMessage defaultMessage="Selvkørende" />,
      onClick,
      render: renderSelfPropelledInternal,
    },

    smallMachine: {
      field: "smallMachine",
      label: <FormattedMessage defaultMessage="Småmaskine" />,
      onClick,
      render: renderSmallMachine,
    },
  };
}

function buildRowData(machineArray: readonly Machine[]): MachineTableDataType[] {
  return machineArray.map((machine) => {
    const {
      active,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      c5_machine,
      canPull,
      name,
      selfPropelled,
      smallMachine,
      url,
    } = machine;

    return {
      active,
      c5_machine,
      canPull: !!canPull,
      key: url,
      name,
      selfPropelled: !!selfPropelled,
      smallMachine,
    };
  });
}

interface MachineTableProps {
  filterString: string;
  onEditMachine: (machineURL: MachineUrl) => void;
  showInactive: boolean;
}

function MachineTable(props: MachineTableProps): React.JSX.Element {
  const {filterString, onEditMachine, showInactive} = props;

  const machineArray = useSelector(getMachineArray);
  const customerSettings = useSelector(getCustomerSettings);

  const dispatch = useDispatch();

  const {disabledMachines, enableSmallMachines, machineLabelVariant} = customerSettings;

  const sortedFilteredMachines = useMemo(() => {
    return (
      machineArray
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .filter(({active, c5_machine}) => {
          return (
            (!c5_machine || (c5_machine && !disabledMachines.includes(c5_machine))) &&
            (active || showInactive)
          );
        })
        .sort((a, b) => identifierComparator(a.c5_machine, b.c5_machine))
    );
  }, [disabledMachines, showInactive, machineArray]);

  const columnSpecifications = useMemo(
    () => buildColumnSpecifications(onEditMachine, machineLabelVariant),
    [machineLabelVariant, onEditMachine],
  );

  const rowData = useMemo(() => buildRowData(sortedFilteredMachines), [sortedFilteredMachines]);

  const visibleColumns = useMemo((): readonly MachineTableColumnID[] => {
    return [
      "c5_machine",
      "name",
      "selfPropelled",
      "canPull",
      enableSmallMachines ? "smallMachine" : null,
      showInactive ? "active" : null,
    ].filter(notNull) as MachineTableColumnID[];
  }, [enableSmallMachines, showInactive]);

  const sortingStateSelector = useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "c5_machine", "ASC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);

  const handleHeaderClick = useCallback(
    (key: MachineTableColumnID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      const action = actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
      dispatch(action);
    },
    [dispatch, sortKey, sortDirection],
  );

  return (
    <GenericTable
      columns={columnSpecifications}
      entries={rowData}
      filterString={filterString}
      onHeaderClick={handleHeaderClick}
      sortBy={sortKey as any}
      sortDirection={sortDirection}
      visibleColumns={visibleColumns}
    />
  );
}

interface MachineListProps {
  showInactive: boolean;
}

export const MachineList = React.memo(function MachineList(props: MachineListProps) {
  const {showInactive} = props;
  const filterString = useQueryParameter("q", "");

  const [machineDialogOpen, setMachineDialogOpen] = useState(false);

  const machineLookup = useSelector(getMachineLookup);
  const [machine, setMachine] = useState<Machine | undefined>();

  const {
    machines: {canCreate, canImport},
  } = useSelector(getExtendedCustomerSettings);

  const handleEditMachine = useCallback(
    (machineURL: MachineUrl) => {
      setMachine(machineLookup(machineURL));
      setMachineDialogOpen(true);
    },
    [machineLookup],
  );
  const handleCreateMachine = useCallback(() => {
    setMachine(undefined);
    setMachineDialogOpen(true);
  }, []);
  const handleMachineCreateEditDialogClose = useCallback(() => {
    setMachine(undefined);
    setMachineDialogOpen(false);
  }, []);

  const fabs: React.JSX.Element[] = [];
  if (canCreate) {
    fabs.push(
      <VerticalStackingFloatingActionButton
        key="create-fab"
        onClick={handleCreateMachine}
        stackIndex={fabs.length}
      >
        <PlusIcon />
      </VerticalStackingFloatingActionButton>,
    );
  }
  if (canImport) {
    fabs.push(
      <ImportMachinesFab buttonStyle={getVerticalStackingFabstyle(fabs.length)} key="import-fab" />,
    );
  }

  return (
    <div>
      <MachineTable
        filterString={filterString}
        onEditMachine={handleEditMachine}
        showInactive={showInactive}
      />
      <MachineCreateEditDialog
        machine={machine}
        onCancel={handleMachineCreateEditDialogClose}
        onOk={handleMachineCreateEditDialogClose}
        open={machineDialogOpen}
      />
      {fabs}
    </div>
  );
});
