import {getWorkTypeString, TaskArchiveData} from "@co-common-libs/resources-utils";
import {
  dateFromString,
  formatAddress,
  formatDate,
  formatDateShort,
  formatDateTime,
  formatDateTimeShort,
  formatDateWeekdayShort,
  formatTime,
} from "@co-common-libs/utils";
import {getCurrentRole, getCustomerSettings} from "@co-frontend-libs/redux";
import {
  buildTaskColumnSpecifications,
  computeTaskVisibleColumns,
  fadeCompleted,
  numericStatusMapping,
  TableWithPagination,
  TableWithPaginationProps,
  TaskTableColumnID,
  TaskTableDataType,
  TaskTableFieldID,
} from "app-components";
import bowser from "bowser";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

function getShortLocationString(
  entries: readonly {
    readonly address: string;
    readonly city: string;
    readonly customerName: string | null;
    readonly name: string;
    readonly postalCode: string;
  }[],
): string {
  return entries
    .map((entry) => {
      const {name} = entry;
      const address = formatAddress(entry);
      if (name && address) {
        return `${name}, ${address}`;
      } else {
        return name || address || "";
      }
    })
    .filter(Boolean)
    .join(", ");
}

function getPostalCodes(
  entries: readonly {
    readonly postalCode: string;
  }[],
): string {
  return entries
    .map((entry) => entry.postalCode)
    .filter(Boolean)
    .join(", ");
}

function getLongLocationString(
  entries: readonly {
    readonly address: string;
    readonly city: string;
    readonly customerName: string | null;
    readonly name: string;
    readonly postalCode: string;
  }[],
): string {
  return entries
    .map((entry) => {
      const {customerName, name} = entry;
      const address = formatAddress(entry);
      return [customerName, name, address].filter(Boolean).join(", ");
    })
    .filter(Boolean)
    .join(", ");
}

const getTimeString = (entry: TaskArchiveData): string => {
  if (entry.workFromTimestamp) {
    return formatTime(entry.workFromTimestamp);
  } else if (entry.date && entry.time) {
    const timestamp = dateFromString(entry.date) as Date;
    const [hours, minutes] = entry.time.split(":");
    timestamp.setHours(parseInt(hours), parseInt(minutes));
    return formatTime(timestamp);
  }
  return "";
};

function buildRowData(
  data: TaskArchiveData[],
  departments: {
    readonly [x: string]: string | undefined;
  },
  showOrderNoteOnTaskList: boolean,
): readonly TaskTableDataType[] {
  const getDepartmentLabel = (departmentID: string): string =>
    departments[departmentID] || departmentID;
  return data.map((entry) => {
    const {notesFromMachineOperator, notesFromManager, orderNotes} = entry;
    let notesString =
      notesFromManager && notesFromMachineOperator
        ? `${notesFromMachineOperator}\n${notesFromManager}`.trim()
        : notesFromManager
          ? notesFromManager.trim()
          : notesFromMachineOperator
            ? notesFromMachineOperator.trim()
            : "";
    if (showOrderNoteOnTaskList && orderNotes) {
      notesString = `${notesString}\n${orderNotes}`.trim();
    }
    let projectString = "";
    let projectWithAliasString = "";
    if (entry.project) {
      const {alias, name, projectNumber} = entry.project;
      const nameAndAlias = name && alias ? `${name}, ${alias}` : name || alias;
      const nameOrAlias = name || alias;
      projectString = `${projectNumber}: ${nameOrAlias}`;
      projectWithAliasString = `${projectNumber}: ${nameAndAlias}`;
    }
    const machinesString = entry.machines.map((m) => `${m.id}: ${m.name}`).join(", ");
    const result: TaskTableDataType = {
      batchOperationCheckbox: false,
      completed: entry.completed,
      createdBy: entry.createdByAlias || "",
      createdDate: formatDate(entry.created),
      createdDateRaw: entry.created || "",
      createdDateShort: formatDateShort(entry.created),
      createdDateTime: formatDateTime(entry.created),
      createdDateTimeShort: formatDateTimeShort(entry.created),
      culture: entry.cultureName || "",
      customer: entry.customer?.name || "",
      customerCultureMachine:
        entry.routeName || entry.customer?.name || entry.cultureName || machinesString,
      customerId: entry.customer?.id || null,
      customerWithAccount: `${entry.customer?.name || ""} - ${entry.customer?.account || ""}`,
      date: formatDate(entry.workFromTimestamp || entry.date),
      dateMedium: formatDateWeekdayShort(entry.date),
      dateRaw: entry.workFromTimestamp || entry.date || "",
      dateShort: formatDateShort(entry.date),
      deliveryLocation: getShortLocationString(entry.deliveryLocations),
      deliveryLocationLong: getLongLocationString(entry.deliveryLocations),
      deliveryPostalCode: getPostalCodes(entry.deliveryLocations),
      department: getDepartmentLabel(entry.department),
      fields: entry.fieldNames.join(", "),
      invoiceNote: entry.invoiceNote,
      isRouteTask: entry.routeName != null,
      key: entry.url,
      machineIDs: entry.machines.map((m) => m.id).join(", "),
      machineOperator: entry.machineOperator?.alias || "",
      machineOperatorName: entry.machineOperator?.name || "",
      machines: machinesString,
      managerInternalNotes: entry.managerInternalNotes,
      notes: notesString,
      numericStatus: numericStatusMapping[entry.status],
      orderUrl: entry.order,
      photoIcon: entry.hasPhoto,
      pickupLocation: getShortLocationString(entry.pickupLocations),
      pickupLocationLong: getLongLocationString(entry.pickupLocations),
      pickupPostalCode: getPostalCodes(entry.pickupLocations),
      priceGroup: entry.priceGroupName || "",
      project: projectString,
      projectWithAlias: projectWithAliasString,
      referenceNumber: entry.referenceNumber || entry.orderReferenceNumber || "",
      reportApproved: entry.reportApproved,
      status: entry.status,
      time: getTimeString(entry),
      validatedAndRecorded: entry.validatedAndRecorded,
      workplace: getShortLocationString(entry.workplaces),
      workplaceLong: getLongLocationString(entry.workplaces),
      workplacePostalCode: getPostalCodes(entry.workplaces),
      workType: getWorkTypeString(entry.workType || undefined),
      workTypeColor: entry.workType?.color || null,
      workTypeName: entry.workType?.name || "",
      workTypeShort: entry.workType?.identifier || "",
    };
    return result;
  });
}

interface ArchiveTaskTableProps
  extends Omit<
    TableWithPaginationProps<TaskTableFieldID, TaskTableColumnID>,
    "columns" | "entries" | "visibleColumns"
  > {
  data: TaskArchiveData[];
  onClick: (taskURL: string) => void;
  onPhotoClick: (taskURL: string) => void;
}

export function ArchiveTaskTable(props: ArchiveTaskTableProps): React.JSX.Element {
  const {
    count,
    data,
    onClick,
    onHeaderClick,
    onPageChange,
    onPhotoClick,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    sortBy,
    sortDirection,
  } = props;
  const currentRole = useSelector(getCurrentRole);
  const userIsManager = !!currentRole?.manager;

  const customerSettings = useSelector(getCustomerSettings);
  const {
    departments,
    enableOrderReferenceNumber,
    enableTaskReferenceNumber,
    orderReferenceNumberLabel,
    showOrderNoteOnTaskList,
    taskListColumns,
    taskListeNoteLines,
    taskReferenceNumberLabel,
  } = customerSettings;

  const intl = useIntl();

  const columnSpecifications = useMemo(
    () =>
      buildTaskColumnSpecifications(
        customerSettings,
        intl.formatMessage,
        onClick,
        onPhotoClick,
        enableOrderReferenceNumber,
        enableTaskReferenceNumber,
        orderReferenceNumberLabel,
        taskReferenceNumberLabel,
        taskListeNoteLines,
      ),
    [
      customerSettings,
      enableOrderReferenceNumber,
      enableTaskReferenceNumber,
      intl.formatMessage,
      onClick,
      onPhotoClick,
      orderReferenceNumberLabel,
      taskListeNoteLines,
      taskReferenceNumberLabel,
    ],
  );

  const visibleColumns = useMemo(
    () =>
      computeTaskVisibleColumns(
        "archive",
        !!bowser.mobile,
        !!bowser.tablet,
        userIsManager,
        taskListColumns,
      ),
    [taskListColumns, userIsManager],
  );
  const rowData = buildRowData(data, departments, showOrderNoteOnTaskList);

  return (
    <TableWithPagination
      columns={columnSpecifications}
      count={count ?? -1}
      entries={rowData}
      onHeaderClick={onHeaderClick}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      page={page}
      rowsPerPage={rowsPerPage}
      rowStyle={userIsManager ? undefined : fadeCompleted}
      sortBy={sortBy}
      sortDirection={sortDirection}
      visibleColumns={visibleColumns}
    />
  );
}
