import {ReadonlyConfig, schema} from "@co-common-libs/config";
import {Location, Role} from "@co-common-libs/resources";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";

type RoleIdentifier = (typeof schema.definitions.roles.enum)[number];

function getRoleIdentifiers(
  role: Pick<
    Role,
    | "jobber"
    | "machineOperator"
    | "manager"
    | "mechanic"
    | "projectManager"
    | "seniorMachineOperator"
  > | null,
): Set<RoleIdentifier> {
  const identifiers = new Set<RoleIdentifier>();
  if (role) {
    if (role.manager) {
      identifiers.add("manager");
    }
    if (role.seniorMachineOperator) {
      identifiers.add("senior_machine_operator");
    }
    if (role.projectManager) {
      identifiers.add("project_manager");
    }
    if (role.machineOperator) {
      identifiers.add("machine_operator");
    }
    if (role.mechanic) {
      identifiers.add("mechanic");
    }
    if (role.jobber) {
      identifiers.add("jobber");
    }
  }
  return identifiers;
}

export type ExtendedLocationSettingsPart = EconomySystemIntegrationKindPart &
  Pick<
    ReadonlyConfig,
    | "allowMachineOperatorToEditLocationLocationType"
    | "economicEnableWorkplaceImport"
    | "economicInvoicePerWorkplace"
    | "economicUploadWorkPlaces"
    | "rolesMayCreateLocations"
    | "rolesMayEditLocations"
  >;

function workplaceChangeAllowedBySync(
  config: ExtendedLocationSettingsPart,
  activeEconomySystemKind: ActiveEconomySystemIntegrationKind,
): boolean {
  const {
    brugerdataSync,
    c5Sync,
    economicEnableWorkplaceImport,
    economicInvoicePerWorkplace,
    economicSync,
    economicUploadWorkPlaces,
    navSync,
    navSyncProfile,
    unicontaSync,
  } = config;
  if (unicontaSync || navSync || brugerdataSync || navSyncProfile === "bejstrupm") {
    return true;
  } else if (c5Sync) {
    return false;
  } else if (economicSync) {
    if (economicUploadWorkPlaces) {
      return true;
    } else {
      return !economicEnableWorkplaceImport && !economicInvoicePerWorkplace;
    }
  } else {
    console.assert(activeEconomySystemKind === ActiveEconomySystemIntegrationKind.NONE);
    return true;
  }
}

export interface ExtendedLocationSettings {
  canChangeWorkplace: boolean;
  canCreateLocation: boolean;
  canCreateWorkplace: boolean;
  canEditField: (
    location: Pick<Location, "geojson" | "remoteUrl">,
    field: keyof Location,
  ) => boolean;
  canMergeLocations: boolean;
  canOpenEditLocation: boolean;
  canOpenEditWorkplace: boolean;
}

export function extendedLocationSettings(
  config: ExtendedLocationSettingsPart,
  role: Pick<
    Role,
    | "jobber"
    | "machineOperator"
    | "manager"
    | "mechanic"
    | "projectManager"
    | "seniorMachineOperator"
  > | null,
): ExtendedLocationSettings {
  const {
    allowMachineOperatorToEditLocationLocationType,
    rolesMayCreateLocations,
    rolesMayEditLocations,
  } = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const roleIdentifiers = getRoleIdentifiers(role);

  const roleCanCreateLocation = rolesMayCreateLocations.some((identifier) =>
    roleIdentifiers.has(identifier),
  );

  const roleCanEditLocation = rolesMayEditLocations.some((identifier) =>
    roleIdentifiers.has(identifier),
  );

  const canChangeWorkplace = workplaceChangeAllowedBySync(config, economySystemIntegrationKind);

  return {
    canChangeWorkplace,
    canCreateLocation: roleCanCreateLocation,
    canCreateWorkplace: roleCanCreateLocation && canChangeWorkplace,
    canEditField: (location: Pick<Location, "geojson" | "remoteUrl">, field: keyof Location) => {
      if (field === "locationType") {
        return (
          !location?.geojson &&
          (roleCanEditLocation || allowMachineOperatorToEditLocationLocationType)
        );
      } else {
        return (
          roleCanEditLocation &&
          (!location.remoteUrl ||
            economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC)
        );
      }
    },
    // TODO(mr): if we refactor to use mass action, we can allow merge of CO-only locations while
    //           preventing merge of externally synced locations
    canMergeLocations:
      roleCanEditLocation &&
      economySystemIntegrationKind !== ActiveEconomySystemIntegrationKind.IMPORT_ONLY,

    canOpenEditLocation: roleCanEditLocation,

    canOpenEditWorkplace: roleCanEditLocation && canChangeWorkplace,
  };
}
