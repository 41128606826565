import {CustomerUrl, Task} from "@co-common-libs/resources";
import {useResettingState} from "@co-frontend-libs/utils";
import React, {useCallback} from "react";
import {ChangeOrMergeTaskCustomer} from "../change-or-merge-task-customer";
import {ChangeTaskCustomer} from "../change-task-customer";
import {MergeTaskCustomer} from "../merge-task-customer";
import {EconomicCustomerSelectDialog} from "./economic-customer-select-dialog";

enum CustomerChangeDisplayState {
  CHANGE_CUSTOMER = "CHANGE_CUSTOMER",
  CHECK_AFFECTED_TASKS = "CHECK_AFFECTED_TASKS",
  MERGE_CUSTOMER = "MERGE_CUSTOMER",
  SELECT_NEW_CUSTOMER = "SELECT_NEW_CUSTOMER",
}

interface EconomicChangeCustomerForTaskWizardProps {
  allowChangeCurrentTaskOnly: boolean;
  onCancel: () => void;
  onFailure: () => void;
  onSuccess: () => void;
  open: boolean;
  task: Task;
}

/**
 * Change the customer for task.
 *
 * Part of the customer approval process where the task customer may have been
 * created by mistake and should in fact have been an existing customer instead.
 *
 * 1) Select customer that exists in economic to replace task customer
 * 2) Check how many tasks have been created using the task customer
 * 3a) One task: Perform merge from task customer to selected customer
 * 3b) Multiple tasks: Ask if the change should affect all or just current task
 *    - All: Perform merge from task customer to selected customer
 *    - Current: Perform offline change of customer (may involve copy to new order)
 * 4) For offline change in 3b: If there are workplaces and `taskWizardAskForWorkPlace` is enabled,
 *    a final selection dialog will allow changing the workplace too.
 */
export const EconomicChangeCustomerForTaskWizard = React.memo(
  function EconomicChangeCustomerForTaskWizard(
    props: EconomicChangeCustomerForTaskWizardProps,
  ): React.JSX.Element | null {
    const {allowChangeCurrentTaskOnly, onCancel, onFailure, onSuccess, open, task} = props;

    const [selectedCustomerUrl, setSelectedCustomerUrl] = useResettingState<CustomerUrl | null>(
      null,
      open,
    );

    const [displayState, setDisplayState] = useResettingState<CustomerChangeDisplayState>(
      CustomerChangeDisplayState.SELECT_NEW_CUSTOMER,
      open,
    );

    const handleCustomerSelected = useCallback(
      (customerUrl: CustomerUrl) => {
        setSelectedCustomerUrl(customerUrl);
        setDisplayState(CustomerChangeDisplayState.CHECK_AFFECTED_TASKS);
      },
      [setDisplayState, setSelectedCustomerUrl],
    );

    const handleChange = useCallback(() => {
      setDisplayState(CustomerChangeDisplayState.CHANGE_CUSTOMER);
    }, [setDisplayState]);

    const handleMerge = useCallback(() => {
      setDisplayState(CustomerChangeDisplayState.MERGE_CUSTOMER);
    }, [setDisplayState]);

    return (
      <>
        <EconomicCustomerSelectDialog
          onCancel={onCancel}
          onOk={handleCustomerSelected}
          open={open && displayState === CustomerChangeDisplayState.SELECT_NEW_CUSTOMER}
        />
        {selectedCustomerUrl ? (
          <>
            <ChangeOrMergeTaskCustomer
              allowChangeCurrentTaskOnly={allowChangeCurrentTaskOnly}
              onCancel={onCancel}
              onChangeCustomer={handleChange}
              onFailure={onFailure}
              onMergeCustomer={handleMerge}
              open={open && displayState === CustomerChangeDisplayState.CHECK_AFFECTED_TASKS}
              task={task}
              toCustomerUrl={selectedCustomerUrl}
            />
            <MergeTaskCustomer
              onFailure={onFailure}
              onSuccess={onSuccess}
              open={open && displayState === CustomerChangeDisplayState.MERGE_CUSTOMER}
              task={task}
              toCustomerUrl={selectedCustomerUrl}
            />
            <ChangeTaskCustomer
              onFailure={onFailure}
              onSuccess={onSuccess}
              open={open && displayState === CustomerChangeDisplayState.CHANGE_CUSTOMER}
              task={task}
              toCustomerUrl={selectedCustomerUrl}
            />
          </>
        ) : null}
      </>
    );
  },
);
