import {ProductGroup, ProductGroupUrl} from "@co-common-libs/resources";
import {caseAccentInsensitiveCollator} from "@co-common-libs/utils";
import {Paper} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import ImageIcon from "mdi-react/ImageIcon";
import React from "react";

const productGrouptileStyle: React.CSSProperties = {
  display: "inline-block",
  height: 218,
  marginRight: 8,
  textAlign: "center",
  verticalAlign: "bottom",
  width: 160,
};

const productImagePaperStyle: React.CSSProperties = {
  height: 160,
  width: 160,
};

const imageStyle: React.CSSProperties = {
  height: 160,
  width: 160,
};

const iconWrapperStyle: React.CSSProperties = {
  padding: 68,
};

interface ProductGroupTileProps {
  onClick: (event: React.MouseEvent, url: ProductGroupUrl) => void;
  productGroup: ProductGroup;
}

class ProductGroupTile extends PureComponent<ProductGroupTileProps> {
  @bind
  handleClick(event: React.MouseEvent): void {
    const {onClick, productGroup} = this.props;
    const {url} = productGroup;
    onClick(event, url);
  }
  render(): React.JSX.Element {
    const {productGroup} = this.props;
    const {name} = productGroup;
    const photoURL = productGroup.photoUrl;
    const image = photoURL ? (
      <img alt="" src={photoURL} style={imageStyle} />
    ) : (
      <div style={iconWrapperStyle}>
        <ImageIcon />
      </div>
    );
    return (
      <div style={productGrouptileStyle}>
        <Paper onClick={this.handleClick} style={productImagePaperStyle}>
          {image}
        </Paper>
        {name}
      </div>
    );
  }
}

function isActive(productGroup: ProductGroup): boolean {
  return productGroup.active;
}

interface ProductGroupGridProps {
  onClick: (event: React.MouseEvent, url: ProductGroupUrl) => void;
  productGroupArray: readonly ProductGroup[];
  selectedGroup?: string | undefined;
}

export class ProductGroupGrid extends PureComponent<ProductGroupGridProps> {
  @bind
  handleProductGroupTileClick(event: React.MouseEvent, url: ProductGroupUrl): void {
    this.props.onClick(event, url);
  }
  render(): React.JSX.Element | null {
    const {productGroupArray, selectedGroup} = this.props;
    const productGroupFilter: (productGroup: ProductGroup) => boolean = selectedGroup
      ? (productGroup) => productGroup.parent === selectedGroup
      : (productGroup) => !productGroup.parent;
    const productGroupList = productGroupArray
      .filter(isActive)
      .filter(productGroupFilter)
      .sort((a, b) => caseAccentInsensitiveCollator.compare(a.name, b.name));
    if (!productGroupList.length) {
      return null;
    }
    const productGroupTileList = productGroupList.map((productGroup) => (
      <ProductGroupTile
        key={productGroup.url}
        onClick={this.handleProductGroupTileClick}
        productGroup={productGroup}
      />
    ));
    return <div>{productGroupTileList}</div>;
  }
}
