import {SettingID, SettingsGroupID, SettingsModuleID} from "@co-common-libs/config";
import {IconButton} from "@material-ui/core";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";
import React, {useEffect, useRef} from "react";
import {GroupData} from "../types";
import {SettingsGroupView} from "./settings-group-view";

interface SettingsModuleViewProps {
  moduleDocumentationURL: string | undefined;
  moduleGroups: readonly GroupData[];
  moduleID: SettingsModuleID;
  moduleTitle: string;
  nonBlankSettings: ReadonlySet<SettingID>;
  onOpenDialog: (settingsID: SettingID) => void;
  selectedGroup?: SettingsGroupID | undefined;
  selectedModule?: SettingsModuleID | undefined;
}

export function SettingsModuleView(props: SettingsModuleViewProps): React.JSX.Element {
  const {
    moduleDocumentationURL,
    moduleGroups,
    moduleID,
    moduleTitle,
    nonBlankSettings,
    onOpenDialog,
    selectedGroup,
    selectedModule,
  } = props;

  const headerRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (selectedModule === moduleID && !selectedGroup) {
      if (headerRef.current) {
        headerRef.current.scrollIntoView();
      }
    }
  }, [selectedModule, selectedGroup, moduleID]);

  return (
    <>
      <a ref={headerRef} style={{position: "relative", top: -64}} />
      <h1 style={{margin: "1em"}}>{moduleTitle}</h1>
      {moduleDocumentationURL ? (
        <IconButton
          color="primary"
          href={moduleDocumentationURL}
          style={{
            position: "relative",
            top: -2,
          }}
          target="blank"
        >
          <InformationOutlineIcon />
        </IconButton>
      ) : null}
      {moduleGroups.map(
        ({
          customOfficeVariants,
          groupDescription,
          groupDocumentationURL,
          groupID,
          groupSettings,
          groupTitle,
        }) => (
          <SettingsGroupView
            customOfficeVariants={customOfficeVariants}
            groupDescription={groupDescription}
            groupDocumentationURL={groupDocumentationURL}
            groupID={groupID}
            groupSettings={groupSettings}
            groupTitle={groupTitle}
            key={groupID}
            nonBlankSettings={nonBlankSettings}
            onOpenDialog={onOpenDialog}
            selectedGroup={selectedGroup}
          />
        ),
      )}
      <br />
    </>
  );
}
