import {ReadonlyConfig} from "@co-common-libs/config";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";

type ExtendedProductSettingsPart = EconomySystemIntegrationKindPart &
  Pick<ReadonlyConfig, "canEditProducts" | "overviewShowPrices">;

export interface ExtendedProductSettings {
  canImport: boolean;
  canManage: boolean;
  showActive: boolean;
  showPrices: boolean;
}

export function extendedProductSettings(
  config: ExtendedProductSettingsPart,
): ExtendedProductSettings {
  const {canEditProducts, overviewShowPrices} = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const integrationAllowsManage =
    economySystemIntegrationKind !== ActiveEconomySystemIntegrationKind.IMPORT_ONLY;

  const isImportOrLiveSyncIntegration =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.IMPORT_ONLY ||
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC;

  return {
    canImport: isImportOrLiveSyncIntegration && canEditProducts,
    canManage: integrationAllowsManage && canEditProducts,
    showActive: economySystemIntegrationKind !== ActiveEconomySystemIntegrationKind.NONE,
    showPrices: overviewShowPrices,
  };
}
