import {ReadonlyConfig} from "@co-common-libs/config";
import {Role, WorkType} from "@co-common-libs/resources";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";

type ExtendedProductGroupSettingsPart = EconomySystemIntegrationKindPart &
  Pick<
    ReadonlyConfig,
    | "canEditExternalWorkType"
    | "canEditInternalWorkTypes"
    | "economicEnableWorkTypeFromProductGroupImport"
    | "importExternalWorkTypesFromProductGroups"
    | "importInternalWorkTypesFromProducts"
  >;

export interface ExtendedWorkTypeSettings {
  canAccessDetailsPage: boolean;
  canCreateExternal: boolean;
  canCreateInternal: boolean;
  canEditField: (
    workType: Pick<WorkType, "barred" | "remoteUrl">,
    field: keyof WorkType,
  ) => boolean;
  canImportExternal: boolean;
  canOpenEditDialog: (
    workType: Pick<
      WorkType,
      "barred" | "externalTaskPrimary" | "externalTaskSecondary" | "internalTaskSecondary"
    >,
  ) => boolean;
}

export function extendedWorkTypeSettings(
  config: ExtendedProductGroupSettingsPart,
  role: Pick<Role, "consultant"> | null,
): ExtendedWorkTypeSettings {
  const {
    canEditExternalWorkType,
    canEditInternalWorkTypes,
    economicEnableWorkTypeFromProductGroupImport,
    economicSync,
    importExternalWorkTypesFromProductGroups,
    importInternalWorkTypesFromProducts,
    unicontaSync,
  } = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const integrationAllowsManageExternal =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE ||
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC;

  const integrationAllowsImport =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.IMPORT_ONLY ||
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC;

  // TODO(mr): make `importExternalWorkTypesFromProductGroups` replace `economicEnableWorkTypeFromProductGroupImport`
  const syncWorkTypes =
    (economicSync && economicEnableWorkTypeFromProductGroupImport) ||
    (unicontaSync &&
      (importInternalWorkTypesFromProducts || importExternalWorkTypesFromProductGroups));

  const externalDataMembers: ReadonlySet<keyof WorkType> = new Set([
    "identifier",
    "name",
    "salesAccount",
  ]);

  return {
    canAccessDetailsPage: Boolean(
      canEditInternalWorkTypes || canEditExternalWorkType || role?.consultant,
    ),
    canCreateExternal: integrationAllowsManageExternal && canEditExternalWorkType,
    canCreateInternal: canEditInternalWorkTypes,
    canEditField: (workType, field) => {
      const isInternalField = !externalDataMembers.has(field);
      const isInternalWorkType = !workType.remoteUrl;

      return (
        isInternalWorkType ||
        (!workType.barred && (isInternalField || integrationAllowsManageExternal))
      );
    },
    canImportExternal: integrationAllowsImport && syncWorkTypes,
    canOpenEditDialog: (workType) => {
      const internal =
        !workType.externalTaskPrimary &&
        !(workType.externalTaskSecondary && workType.internalTaskSecondary);

      return (
        !workType.barred &&
        ((internal && canEditInternalWorkTypes) || (!internal && canEditExternalWorkType))
      );
    },
  };
}
