import {ReadonlyConfig} from "@co-common-libs/config";
import {Machine} from "@co-common-libs/resources";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";

export type ExtendedMachineSettingsPart = EconomySystemIntegrationKindPart &
  Pick<
    ReadonlyConfig,
    | "canCreateMachines"
    | "canEditMachines"
    | "economicEnableMachinesDepartmentSync"
    | "economicEnableMachinesProjectEmployeesSync"
    | "unicontaMachineDimension"
    | "unicontaVariantDME"
  >;

export type EditFieldMachine = Pick<
  Machine,
  "active" | "barred" | "canPull" | "remoteUrl" | "selfPropelled"
>;

export interface ExtendedMachineSettings {
  canAccessDetailsPage: boolean;
  canCreate: boolean;
  canEditField: (machine: EditFieldMachine, field: keyof Machine) => boolean;
  canImport: boolean;
  canOpenEditDialog: boolean;
}

const externalDataMembers: ReadonlySet<keyof Machine> = new Set(["barred", "c5_machine"]);

export function extendedMachineSettings(
  config: ExtendedMachineSettingsPart,
): ExtendedMachineSettings {
  const {
    brugerdataSync,
    c5Sync,
    canCreateMachines,
    canEditMachines,
    economicEnableMachinesDepartmentSync,
    economicEnableMachinesProjectEmployeesSync,
    economicSync,
    navSync,
    navSyncProfile,
    unicontaMachineDimension,
    unicontaSync,
    unicontaVariantDME,
  } = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const integrationSupportsCreateEdit =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE ||
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC;

  const integrationSupportsImport =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.IMPORT_ONLY ||
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC;

  const hasMachineSyncEnabled =
    (economicSync &&
      (economicEnableMachinesDepartmentSync || economicEnableMachinesProjectEmployeesSync)) ||
    (unicontaSync && unicontaMachineDimension !== null) ||
    c5Sync ||
    (navSync && navSyncProfile !== null) ||
    brugerdataSync;

  const systemSpecificExternalMembers: Set<keyof Machine> = new Set();

  if (c5Sync && navSyncProfile === "bejstrupm") {
    systemSpecificExternalMembers.add("canPull");
    systemSpecificExternalMembers.add("selfPropelled");
  } else if (navSync && navSyncProfile !== "dme" && navSyncProfile !== "vredodanmark") {
    systemSpecificExternalMembers.add("canPull");
    systemSpecificExternalMembers.add("selfPropelled");
  } else if (unicontaSync) {
    systemSpecificExternalMembers.add("canPull");
  }

  return {
    canAccessDetailsPage: canEditMachines,
    canCreate: canCreateMachines && integrationSupportsCreateEdit,
    canEditField: (machine: EditFieldMachine, field: keyof Machine) => {
      if (machine.barred) {
        return false;
      }

      let passAdditionalFieldRequirements = true;
      if (field === "selfPropelled") {
        // see https://github.com/customoffice-dk/customoffice/issues/1150
        passAdditionalFieldRequirements = !(unicontaVariantDME && machine.canPull);
      }

      if (field === "hourlyTowingCostEffective" || field === "hourlyTowingCostTransport") {
        passAdditionalFieldRequirements = !machine.canPull && !machine.selfPropelled;
      }

      if (hasMachineSyncEnabled) {
        // We only allow going from active to inactive using the edit dialog.
        // Reactivating an inactive machine is done by re-importing it.
        if (field === "active") {
          return machine.active;
        }

        const isInternalField =
          !externalDataMembers.has(field) && !systemSpecificExternalMembers.has(field);
        return (
          (isInternalField || integrationSupportsCreateEdit) && passAdditionalFieldRequirements
        );
      } else {
        return passAdditionalFieldRequirements;
      }
    },
    canImport: canEditMachines && hasMachineSyncEnabled && integrationSupportsImport,
    canOpenEditDialog: canEditMachines,
  };
}
