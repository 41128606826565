import {ProductGroup, urlToId} from "@co-common-libs/resources";
import {IntegerField, ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  actions,
  diffResourceInstanceProperties,
  getCurrentlyFetchingChanges,
  getCustomerSettings,
  getProductGroupArray,
  getWorkTypeArray,
} from "@co-frontend-libs/redux";
import {
  jsonFetch,
  translateNetworkError,
  useCallWithFalse,
  useCallWithTrue,
} from "@co-frontend-libs/utils";
import {
  Button,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  Switch,
  useTheme,
} from "@material-ui/core";
import {createProductGroup} from "app-utils";
import {ImportAccountDialog} from "feat-import-resources";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const DISPLAY_SPINNER_MIN_MS = 1000;

export const ProductGroupCreateEditDialog = React.memo(
  function ProductGroupCreateEditDialog(props: {
    onCancel: () => void;
    onOk: (productGroupUrl: string) => void;
    open: boolean;
    productGroup?: ProductGroup | undefined;
  }): React.JSX.Element {
    const {onOk, open, productGroup} = props;
    const [name, setName] = useState(productGroup?.name || "");
    const [active, setActive] = useState(productGroup ? productGroup.active : true);
    const [salesAccount, setSalesAccount] = useState<number | null>(
      productGroup?.salesAccount ?? null,
    );
    const [identifier, setIdentifier] = useState(productGroup?.identifier || "");

    const [saving, setSaving] = useState(false);
    const setSavingFalse = useCallWithFalse(setSaving);

    const [accountDialogOpen, setAccountDialogOpen] = useState(false);
    const setAccountDialogOpenTrue = useCallWithTrue(setAccountDialogOpen);
    const setAccountDialogOpenFalse = useCallWithFalse(setAccountDialogOpen);

    const handleIdentifierChange = useCallback((value: number | null) => {
      setIdentifier(value?.toString() || "");
    }, []);

    const workTypeArray = useSelector(getWorkTypeArray);

    const workTypeWithSameRemote = useMemo(
      () =>
        productGroup?.remoteUrl
          ? workTypeArray.find((w) => w.remoteUrl === productGroup.remoteUrl)
          : null,
      [workTypeArray, productGroup?.remoteUrl],
    );
    const customerSettings = useSelector(getCustomerSettings);

    const productGroupArray = useSelector(getProductGroupArray);

    const productGroupNumberDuplicate: boolean = useMemo(() => {
      if (!identifier) {
        return false;
      } else {
        return productGroupArray.some(
          (group) => group.identifier === identifier && group.url !== productGroup?.url,
        );
      }
    }, [identifier, productGroup?.url, productGroupArray]);

    const workTypeNumberDuplicate: boolean = useMemo(() => {
      if (!customerSettings.economicSync || !identifier) {
        return false;
      } else {
        return workTypeArray.some(
          (workType) =>
            workType.identifier === identifier && workType.remoteUrl !== productGroup?.remoteUrl,
        );
      }
    }, [customerSettings.economicSync, identifier, productGroup?.remoteUrl, workTypeArray]);

    const handleActiveChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      setActive(checked);
    }, []);

    useEffect(() => {
      if (open) {
        setName(productGroup?.name || "");
        setIdentifier(productGroup?.identifier || "");
        setSalesAccount(productGroup?.salesAccount ?? null);
        setActive(productGroup ? productGroup.active : true);
        setErrorMessage("");
      }
    }, [
      open,
      productGroup,
      productGroup?.active,
      productGroup?.identifier,
      productGroup?.name,
      productGroup?.salesAccount,
    ]);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const intl = useIntl();

    const dispatch = useDispatch();

    const currentlyFetching = useSelector(getCurrentlyFetchingChanges);
    const savedUrl = useRef(null);

    const [refreshPendingTimeout, setRefreshPendingTimeout] = useState(false);

    useEffect(() => {
      if (!currentlyFetching && !refreshPendingTimeout && saving && savedUrl.current) {
        onOk(savedUrl.current);
        setSaving(false);
        savedUrl.current = null;
      }
    }, [currentlyFetching, onOk, refreshPendingTimeout, saving, setSavingFalse]);

    const handleOk = useCallback(async () => {
      if (customerSettings.economicSync) {
        let savingError = false;
        const {baseURL} = globalConfig.resources;
        if (productGroup) {
          if (
            name !== productGroup.name ||
            active !== productGroup.active ||
            salesAccount !== productGroup.salesAccount
          ) {
            setSaving(true);

            try {
              const url = `${baseURL}economic/productgroup/${urlToId(productGroup.url)}`;
              const response = await jsonFetch(url, "POST", {
                active,
                name,
                salesAccount,
              });
              dispatch(actions.addToOffline(response.data));
              savedUrl.current = response.data.url;
            } catch (error) {
              savingError = true;
              setSaving(false);
              setErrorMessage(translateNetworkError(error, intl));
            }
            if (!savingError) {
              setRefreshPendingTimeout(true);
              window.setTimeout(() => {
                setRefreshPendingTimeout(false);
              }, DISPLAY_SPINNER_MIN_MS);
              dispatch(actions.requestChangesFetch());
            } else {
              setSaving(false);
            }
          } else {
            onOk(productGroup.url);
          }
        } else {
          setSaving(true);

          const url = `${baseURL}economic/productgroup/create`;
          try {
            const response = await jsonFetch(url, "POST", {
              active,
              identifier,
              name,
              salesAccount,
            });
            dispatch(actions.addToOffline(response.data));
            onOk(response.data.url);
          } catch (error) {
            savingError = true;
            setSaving(false);
            setErrorMessage(translateNetworkError(error, intl));
          }
          setSaving(false);
        }
      } else {
        if (productGroup) {
          const patch = diffResourceInstanceProperties(
            {active, identifier, name, salesAccount},
            productGroup,
          );

          if (patch.length) {
            dispatch(actions.update(productGroup.url, patch));
          }
          onOk(productGroup.url);
        } else {
          const newProductGroup = createProductGroup({
            identifier,
            name,
            salesAccount,
          });

          dispatch(actions.create(newProductGroup));
          onOk(newProductGroup.url);
        }
      }
    }, [
      customerSettings.economicSync,
      productGroup,
      name,
      active,
      dispatch,
      intl,
      identifier,
      salesAccount,
      onOk,
    ]);

    const theme = useTheme();
    const {economicSync} = customerSettings;

    const duplicateNumberError = productGroupNumberDuplicate
      ? intl.formatMessage({
          defaultMessage: "Der eksisterer allerede en varegruppe med det ID",
        })
      : workTypeNumberDuplicate
        ? intl.formatMessage({
            defaultMessage:
              "Der eksisterer allerede et område (Varegruppe i e-conomic) med det ID. Du kan importere varegruppen istedet.",
          })
        : undefined;

    const handleAccountDialogOk = useCallback((account: string) => {
      setSalesAccount(parseInt(account));
      setAccountDialogOpen(false);
    }, []);

    const duplicateNumber = productGroupNumberDuplicate || workTypeNumberDuplicate;
    return (
      <>
        <ResponsiveDialog
          okDisabled={
            !name || !identifier || saving || duplicateNumber || (economicSync && !salesAccount)
          }
          onCancel={props.onCancel}
          onOk={handleOk}
          open={open && !accountDialogOpen}
          title={
            props.productGroup ? (
              <FormattedMessage defaultMessage="Redigér varegruppe" />
            ) : (
              <FormattedMessage defaultMessage="Opret varegruppe" />
            )
          }
        >
          <DialogContent>
            {economicSync ? (
              <>
                {productGroup?.barred ? (
                  <div style={{color: theme.palette.warning.main}}>
                    <FormattedMessage defaultMessage="Varegruppen er blevet slettet i e-conomic" />
                  </div>
                ) : null}
                {workTypeWithSameRemote ? (
                  <FormattedMessage
                    defaultMessage="Hvis du ændre navn her vil det også blive ændret på
                    området {workTypeWithSameRemote}"
                    values={{
                      workTypeWithSameRemote: `${workTypeWithSameRemote.identifier}: ${workTypeWithSameRemote.name}`,
                    }}
                  />
                ) : null}
                <IntegerField
                  autoFocus={!props.productGroup}
                  disabled={saving || !!productGroup?.remoteUrl}
                  error={duplicateNumber}
                  fullWidth
                  helperText={!saving ? duplicateNumberError : null}
                  label={intl.formatMessage({
                    defaultMessage: "ID *",
                  })}
                  margin="dense"
                  onChange={handleIdentifierChange}
                  value={identifier ? parseInt(identifier) : null}
                />
              </>
            ) : (
              <TrimTextField
                autoFocus={!props.productGroup}
                error={productGroupNumberDuplicate}
                fullWidth
                helperText={duplicateNumberError}
                label={intl.formatMessage({
                  defaultMessage: "ID *",
                })}
                margin="dense"
                onChange={setIdentifier}
                value={identifier}
                variant="outlined"
              />
            )}

            <TrimTextField
              autoFocus={!!props.productGroup}
              disabled={saving}
              fullWidth
              label={intl.formatMessage({
                defaultMessage: "Navn *",
              })}
              margin="dense"
              onChange={setName}
              value={name}
              variant="outlined"
            />
            {economicSync ? (
              <>
                <Button
                  color="secondary"
                  onClick={setAccountDialogOpenTrue}
                  style={{marginTop: 2}}
                  variant="contained"
                >
                  <FormattedMessage defaultMessage="Vælg konto" />
                </Button>
                <div style={{whiteSpace: "pre-line"}}>{salesAccount}</div>
                <FormControlLabel
                  control={<Switch checked={active} onChange={handleActiveChange} />}
                  disabled={!!productGroup?.barred}
                  label={intl.formatMessage({defaultMessage: "Aktiv"})}
                />
              </>
            ) : null}
            <FormattedMessage defaultMessage="* Skal udfyldes" tagName="div" />
            {errorMessage ? (
              <h3
                style={{
                  color: theme.palette.error.main,
                  whiteSpace: "pre-line",
                }}
              >
                {errorMessage}
              </h3>
            ) : null}
            {saving ? (
              <div style={{textAlign: "center"}}>
                <CircularProgress />
              </div>
            ) : null}
          </DialogContent>
        </ResponsiveDialog>
        <ImportAccountDialog
          onCancel={setAccountDialogOpenFalse}
          onOk={handleAccountDialogOk}
          open={accountDialogOpen}
        />
      </>
    );
  },
);
