import {TaskTableColumnID} from "../connected-task-table";

export function getSortOptionTaskArchive(
  sortDirection: "ASC" | "DESC",
  sortKey: TaskTableColumnID,
  enableTaskReferenceNumber?: boolean | undefined,
): string[] {
  if (sortKey) {
    const desc = sortDirection === "DESC";
    switch (sortKey) {
      case "created":
      case "createdDate":
      case "createdDateShort":
      case "createdShort":
        return [`${desc ? "-" : ""}version_birth_date`];
      case "createdBy":
      case "createdByShort":
        return [`${desc ? "-" : ""}created_by__login_identifier`];
      case "culture":
        return [`${desc ? "-" : ""}order__culture__name`];
      case "cultureMachine":
        return [
          `${desc ? "-" : ""}order__culture__name`,
          `${desc ? "-" : ""}machineuse__machine__name`,
        ];
      case "customer":
        return [`${desc ? "-" : ""}order__customer__name`];
      case "customerCultureMachine":
        return [
          `${desc ? "-" : ""}order__customer__name`,
          `${desc ? "-" : ""}order__culture__name`,
          `${desc ? "-" : ""}machineuse__machine__name`,
        ];
      case "customerMachine":
        return [
          `${desc ? "-" : ""}order__customer__name`,
          `${desc ? "-" : ""}machineuse__machine__name`,
        ];
      case "customerRoute":
        return [
          `${desc ? "-" : ""}order__customer__name`,
          `${desc ? "-" : ""}order__route_plan__name`,
        ];
      case "customerRouteCultureMachine":
        return [
          `${desc ? "-" : ""}order__customer__name`,
          `${desc ? "-" : ""}order__culture__name`,
          `${desc ? "-" : ""}machineuse__machine__name`,
          `${desc ? "-" : ""}order__route_plan__name`,
        ];
      case "customerRouteMachine":
        return [
          `${desc ? "-" : ""}order__customer__name`,
          `${desc ? "-" : ""}machineuse__machine__name`,
          `${desc ? "-" : ""}order__route_plan__name`,
        ];
      case "date":
      case "dateShort":
        return [`${desc ? "-" : ""}_combined_work_from_timestamp_and_date`];
      case "deliveryLocation":
        return [`${desc ? "-" : ""}workplace__name`];
      case "department":
        return [`${desc ? "-" : ""}department`, "-date", "-time"];
      case "fields":
        return [`${desc ? "-" : ""}fielduse__related_field__name`];
      case "invoiceNote":
        return [`${desc ? "-" : ""}invoice_note`];
      case "machine":
      case "machineShort":
        return [`${desc ? "-" : ""}machineuse__machine__c5_machine`];
      case "machineOperator":
      case "machineOperatorShort":
        return [`${desc ? "-" : ""}machine_operator__initials`];
      case "machines":
        return [`${desc ? "-" : ""}machineuse__machine__name`];
      case "managerInternalNotes":
        return [`${desc ? "-" : ""}manager_internal_notes`];
      case "notes":
        return [
          `${desc ? "-" : ""}notes_from_manager`,
          `${desc ? "-" : ""}notes_from_machine_operator`,
        ];
      case "pickupLocation":
        return [`${desc ? "-" : ""}pickup_location__name`];
      case "priceGroup":
        return [`${desc ? "-" : ""}price_group__name`];
      case "project":
      case "projectWithAlias":
        return [
          `${desc ? "-" : ""}project__project_number`,
          `${desc ? "-" : ""}project__name`,
          `${desc ? "-" : ""}project__alias`,
        ];
      case "referenceNumber":
        return enableTaskReferenceNumber
          ? [`${desc ? "-" : ""}reference_number`]
          : [`${desc ? "-" : ""}order__reference_number`];
      case "time":
      case "timeShort":
        return [`${desc ? "-" : ""}time`];
      case "workType":
      case "workTypeShort":
        return [`${desc ? "-" : ""}work_type__name`];
      default:
        return [`${desc ? "-" : ""}created`];
    }
  } else {
    return ["-date", "-time"];
  }
}
