import {Customer} from "@co-common-libs/resources";
import {TranslatedError} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {translateNetworkError, useCallWithFalse, useResettingState} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import {simpleObjectReducer, useResetObjectOnCondition} from "app-utils";
import React, {useCallback, useReducer} from "react";
import {useIntl} from "react-intl";
import {batch, useDispatch} from "react-redux";
import {CustomerFormWithCvrAutocomplete} from "../../customer-form/customer-form-with-cvr-autocomplete";
import {hasMissingOrInvalidInputs} from "../../customer-form/has-missing-or-invalid-inputs";
import {transformToCustomerFormPart} from "../../customer-form/transform-to-customer-form-part";
import {CustomerFormPart} from "../../customer-form/types";
import {useCustomerFormErrorsAndWarnings} from "../../customer-form/use-customer-form-errors-and-warnings";
import {useCustomerFormInputDefinitions} from "../../customer-form/use-customer-form-input-definitions";
import {ProcessingDialog} from "../../processing-dialog";
import {requestEditCustomerInEconomic} from "./economic-requests";

interface EconomicCustomerEditDialogProps {
  customer: Customer;
  onCancel: () => void;
  onSuccess: () => void;
  open: boolean;
}
export const EconomicCustomerEditDialog = React.memo(function EconomicCustomerEditDialog(
  props: EconomicCustomerEditDialogProps,
): React.JSX.Element {
  const {customer, onCancel, onSuccess, open} = props;

  const [savingPromise, setSavingPromise] = useResettingState<Promise<Customer> | null>(null, open);
  const [saveInProgress, setSaveInProgress] = useResettingState<boolean>(false, open);
  const setSaveInProgressFalse = useCallWithFalse(setSaveInProgress);

  const [formCustomer, formDispatch] = useReducer(
    simpleObjectReducer<CustomerFormPart>,
    customer,
    transformToCustomerFormPart,
  );
  useResetObjectOnCondition(customer, formDispatch, open, transformToCustomerFormPart);
  const inputDefinitions = useCustomerFormInputDefinitions(customer);
  const errorsAndWarnings = useCustomerFormErrorsAndWarnings(
    formCustomer,
    customer,
    inputDefinitions,
  );

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleOk = useCallback(() => {
    setSavingPromise(
      requestEditCustomerInEconomic({
        ...formCustomer,
        url: customer.url,
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw new TranslatedError(translateNetworkError(error, intl));
        }),
    );

    setSaveInProgress(true);
  }, [customer.url, formCustomer, intl, setSaveInProgress, setSavingPromise]);

  const handleEditSuccess = useCallback(
    (data: Customer) => {
      batch(() => {
        dispatch(actions.addToOffline(data));
        onSuccess();
      });
    },
    [dispatch, onSuccess],
  );

  return (
    <>
      {savingPromise ? (
        <ProcessingDialog
          onClose={setSaveInProgressFalse}
          onSuccess={handleEditSuccess}
          processPromise={savingPromise}
          title={intl.formatMessage({defaultMessage: "Gemmer ændringer"})}
        />
      ) : null}
      <ResponsiveDialog
        okDisabled={hasMissingOrInvalidInputs(formCustomer, inputDefinitions, errorsAndWarnings)}
        onCancel={onCancel}
        onOk={handleOk}
        open={open && !saveInProgress}
        title={intl.formatMessage({defaultMessage: "Redigér kunde"})}
      >
        <DialogContent>
          <CustomerFormWithCvrAutocomplete
            formCustomer={formCustomer}
            formDispatch={formDispatch}
            formWarningText={
              customer.barred
                ? intl.formatMessage({
                    defaultMessage: "Kunden er blevet slettet eller spærret i e-conomic",
                  })
                : ""
            }
            {...inputDefinitions}
            {...errorsAndWarnings}
          />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
});
