import {Config} from "@co-common-libs/config";
import {
  Contact,
  Customer,
  CustomerUrl,
  Delivery,
  DeliveryLocation,
  DeliveryLocationUrl,
  Location,
  LocationUrl,
  Order,
  OrderUrl,
  PatchOperation,
  PatchUnion,
  Pickup,
  PickupLocation,
  PickupLocationUrl,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductUrl,
  resourceNameFor,
  ResourceTypeUnion,
  Role,
  Task,
  TaskUrl,
  TransportLog,
  TransportLogReport,
  Unit,
  UnitUrl,
  urlToId,
  UserProfile,
  UserUrl,
} from "@co-common-libs/resources";
import {
  getNormalisedDeviceTimestamp,
  getUnitCode,
  getUnitString,
} from "@co-common-libs/resources-utils";
import {formatDate, formatTime, sortByOrderMember} from "@co-common-libs/utils";
import {
  DeleteDialog,
  FilePdfIcon,
  ResponsiveDialog,
  SpinnerDialog,
} from "@co-frontend-libs/components";
import {
  actions,
  AppState,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getDeliveryArray,
  getDeliveryLocationArray,
  getDeliveryLocationLookup,
  getLocationLookup,
  getOrderLookup,
  getPickupArray,
  getPickupLocationArray,
  getPickupLocationLookup,
  getPriceItemLookup,
  getProductLookup,
  getShareToken,
  getSortedActiveContactArrayPerCustomer,
  getTaskArray,
  getTaskLookup,
  getTransportLogArray,
  getTransportLogReportArray,
  getUnitLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  CustomerSelectCreateDialog,
  DeliveryDialog,
  DeliveryLocationDialog,
  getLocationButtonString,
  MailReportDialog,
  PickupDialog,
  PickupLocationDialog,
} from "app-components";
import {computePatch, PureComponent, sameMinute} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {globalConfig, instanceURL} from "frontend-global-config";
import _ from "lodash";
import DeleteIcon from "mdi-react/DeleteIcon";
import FileDelimitedIcon from "mdi-react/FileDelimitedIcon";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import MailIcon from "mdi-react/MailIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback} from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";
import {TransportLogTotalsCard} from "./transport-log-totals-card";
import {getCustomerMailAddress} from "./yield-log-card";

const messages = defineMessages({
  addDeliveryLocation: {
    defaultMessage: "Tilføj sted",
    id: "task-instance.label.add-delivery-location",
  },
  addPickupLocation: {
    defaultMessage: "Tilføj sted",
    id: "task-instance.label.add-pickup-location",
  },
  alternativeTransferTotals: {
    defaultMessage: "Overfør til materialelinjer",
    id: "task-instance.alternative-label.transfer-totals",
  },
  editTransportLog: {
    defaultMessage: "Ret",
    id: "task-instance.label.edit-transport-log",
  },
  mailError: {
    defaultMessage: "Der skete en fejl under afsendelsen af emailen",
    id: "transport-log-card.label.mailerror",
  },
  mailMessage: {
    defaultMessage: `Denne mail er sendt til dig fra {companyName} via software leveret af CustomOffice ApS og kan derfor ikke besvares.
Ønsker du at svare på denne mail eller ønsker du ikke at modtage denne type mails fremover, skal du henvende dig til {companyName}.`,
    id: "transport-log-card.label.yield-log",
  },
  reports: {
    defaultMessage: "Udskrifter, transportlog",
    id: "transportlog-report.card-title.pdf-reports",
  },
  sendingMail: {
    defaultMessage: "Sender mail",
    id: "transport-log-card.label.sending-mail",
  },
  transferTotals: {
    defaultMessage: "Overfør til materiellinjer",
    id: "task-instance.label.transfer-totals",
  },
  transportlog: {
    defaultMessage: "Transport log",
    id: "transport-log-card.label.transport-log",
  },
});

const TIME_COLUMN_STYLE = {width: 86};
const AMOUNT_COLUMN_STYLE = {width: 100};

const ReportRow = ({
  createdBy,
  csvDownloadURL,
  deviceTimestamp,
  includeTransportlogOverviewVariant,
  onMailIconClick,
  overviewPdfDownloadURL,
  pdfDownloadURL,
  tokenPart,
  url,
}: {
  createdBy?: string | undefined;
  csvDownloadURL?: string | undefined;
  deviceTimestamp: string;
  includeTransportlogOverviewVariant: boolean;
  onMailIconClick: (url: string) => void;
  overviewPdfDownloadURL?: string | undefined;
  pdfDownloadURL?: string | undefined;
  tokenPart: string;
  url: string;
}): React.JSX.Element => {
  const handleMailIconClick = useCallback(() => {
    onMailIconClick(url);
  }, [onMailIconClick, url]);

  let pendingSpinner;
  const actionButtons: React.JSX.Element[] = [];
  if (!pdfDownloadURL) {
    pendingSpinner = <CircularProgress />;
  } else {
    if (includeTransportlogOverviewVariant) {
      if (overviewPdfDownloadURL) {
        actionButtons.push(
          <IconButton
            color="primary"
            href={overviewPdfDownloadURL + tokenPart}
            key="overview-pdf"
            target="_blank"
          >
            <FilePdfIcon />
          </IconButton>,
        );
      } else {
        actionButtons.push(
          <IconButton disabled key="overview-disabled-pdf">
            <FilePdfIcon />
          </IconButton>,
        );
      }
      actionButtons.push(
        <IconButton color="primary" href={pdfDownloadURL + tokenPart} key="pdf" target="_blank">
          <FileDocumentIcon />
        </IconButton>,
      );
    } else {
      actionButtons.push(
        <IconButton color="primary" href={pdfDownloadURL + tokenPart} key="pdf" target="_blank">
          <FilePdfIcon />
        </IconButton>,
      );
    }
    actionButtons.push(
      <IconButton color="primary" href={csvDownloadURL + tokenPart} key="csv" target="_blank">
        <FileDelimitedIcon />
      </IconButton>,
    );

    actionButtons.push(
      <IconButton color="primary" key="mail" onClick={handleMailIconClick}>
        <MailIcon />
      </IconButton>,
    );
  }
  const buttonColumnWidth = 2 * 24 + 48 + 48 + 48 + (includeTransportlogOverviewVariant ? 48 : 0);
  return (
    <TableRow>
      <TableCell>{createdBy}</TableCell>
      <TableCell>
        {formatDate(deviceTimestamp)}
        &nbsp;
        {formatTime(deviceTimestamp)}
      </TableCell>
      <TableCell style={{width: buttonColumnWidth}}>
        {pendingSpinner}
        {actionButtons}
      </TableCell>
    </TableRow>
  );
};

interface ReportsCardStateProps {
  contactArrayPerCustomer: ReadonlyMap<string, readonly Contact[]>;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  taskLookup: (url: TaskUrl) => Task | undefined;
  transportLogReportArray: readonly TransportLogReport[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface ReportsCardOwnProps {
  amountUnit: string;
  currentUserURL: string | null;
  customerSettings: Config;
  entryData: readonly unknown[];
  onRequestBuildReports: () => void;
  shareToken: string | null;
  transportLog: TransportLog;
  workTypeString: string;
}

type ReportsCardProps = ReportsCardOwnProps & ReportsCardStateProps;

interface ReportsCardState {
  mailError: boolean;
  mailReportDialogOpenFor: string | null;
  sendingMail: boolean;
}

class ReportsCard extends React.Component<ReportsCardProps, ReportsCardState> {
  static contextType = IntlContext;
  _sending: boolean = false;
  context!: React.ContextType<typeof IntlContext>;
  state = {
    mailError: false,
    mailReportDialogOpenFor: null,
    sendingMail: false,
  };
  @bind
  handleMailClick(url: string): void {
    this.setState({mailReportDialogOpenFor: url});
  }
  @bind
  handleMailErrorDialogOk(): void {
    this.setState({mailError: false});
  }

  @bind
  handleMailReportDialogCancel(): void {
    this.setState({mailReportDialogOpenFor: null});
  }

  @bind
  handleMailReportDialogOk(subject: string, message: string, recipient: string): void {
    const {mailReportDialogOpenFor} = this.state;
    if (this._sending || !mailReportDialogOpenFor) {
      return;
    }
    this._sending = true;

    const {baseURL} = globalConfig;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const reportId = urlToId(mailReportDialogOpenFor!);
    const url = `${baseURL}/api/mail/transportlog_report/${reportId}`;
    this.setState({mailReportDialogOpenFor: null, sendingMail: true});
    jsonFetch(url, "POST", {
      message,
      recipient,
      subject,
    })
      .then(() => {
        this._sending = false;
        this.setState({
          sendingMail: false,
        });
        return;
      })
      .catch(() => {
        this._sending = false;
        this.setState({
          mailError: true,
          sendingMail: false,
        });
      });
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;
    const {
      contactArrayPerCustomer,
      customerLookup,
      entryData,
      orderLookup,
      taskLookup,
      transportLog,
      transportLogReportArray,
      userUserProfileLookup,
    } = this.props;

    const {includeTransportlogOverviewVariant} = customerSettings;
    const transportLogURL = transportLog.url;
    const recipient = getCustomerMailAddress(transportLog.task, {
      contactArrayPerCustomer,
      customerLookup,
      orderLookup,
      taskLookup,
    });

    const tokenPart = `?token=${this.props.shareToken}`;
    const buttonColumnWidth = 2 * 24 + 48 + 48 + 48 + (includeTransportlogOverviewVariant ? 48 : 0);
    const addButtonStyle: React.CSSProperties = {
      position: "absolute",
      right: 23,
      top: 23,
    };
    const tableRows = _.sortBy(
      transportLogReportArray.filter((r) => r.transportlog === transportLogURL),
      (r) => r.serverTimestamp,
    )
      .reverse()
      .map((report) => {
        const pdfDownloadURL = report.pdfDownload;
        const csvDownloadURL = report.csvDownload;
        const overviewPdfDownloadURL = report.overviewPdfDownload;
        const createdByURL = report.createdBy;
        const createdByProfile = userUserProfileLookup(createdByURL);

        return (
          <ReportRow
            createdBy={createdByProfile?.alias}
            csvDownloadURL={csvDownloadURL}
            deviceTimestamp={report.deviceTimestamp}
            includeTransportlogOverviewVariant={includeTransportlogOverviewVariant}
            key={report.url}
            onMailIconClick={this.handleMailClick}
            overviewPdfDownloadURL={overviewPdfDownloadURL}
            pdfDownloadURL={pdfDownloadURL}
            tokenPart={tokenPart}
            url={report.url}
          />
        );
      });
    return (
      <>
        <Card style={{margin: "1em"}}>
          <div style={{position: "relative"}}>
            <Fab
              disabled={!entryData.length}
              onClick={this.props.onRequestBuildReports}
              size="small"
              style={addButtonStyle}
            >
              <PlusIcon />
            </Fab>
          </div>
          <CardHeader title={formatMessage(messages.reports)} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Udskrevet af"
                    id="transportlog-report.table-header.printed-by"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Tidspunkt"
                    id="transportlog-report.table-header.printed-on"
                  />
                </TableCell>
                <TableCell style={{width: buttonColumnWidth}}>
                  <FormattedMessage
                    defaultMessage="Download"
                    id="transportlog-report.table-header.download"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </Card>
        <MailReportDialog
          defaultMessage={formatMessage(messages.mailMessage, {
            companyName: this.props.customerSettings.companyName,
          })}
          logName={formatMessage(messages.transportlog)}
          onCancel={this.handleMailReportDialogCancel}
          onOk={this.handleMailReportDialogOk}
          open={!!this.state.mailReportDialogOpenFor}
          recipient={recipient}
        />
        <SpinnerDialog open={this.state.sendingMail} title={formatMessage(messages.sendingMail)} />
        <ResponsiveDialog
          onOk={this.handleMailErrorDialogOk}
          open={this.state.mailError === true}
          title={formatMessage(messages.mailError)}
        />
      </>
    );
  }
}

interface DeliveryEntryData {
  action: "delivery";
  amount: number;
  area: number | null;
  customerName: string | null;
  distance: number | null;
  entry: Delivery;
  location: string;
  locationUuid: string;
  note: string;
  place: string;
  relatedUnitUrl?: UnitUrl | undefined;
  timestamp: string | null;
  unit: string;
}

interface PickupEntryData {
  action: "pickup";
  amount: number;
  area: number | null;
  customerName: string | null;
  distance: number | null;
  entry: Pickup;
  location: string;
  locationUuid: string;
  note: string;
  place: string;
  relatedUnitUrl?: UnitUrl | undefined;
  timestamp: string | null;
  unit: string;
}

const ConnectedReportsCard: React.ComponentType<ReportsCardOwnProps> = connect<
  ReportsCardStateProps,
  object,
  ReportsCardOwnProps,
  AppState
>(
  createStructuredSelector<AppState, ReportsCardStateProps>({
    contactArrayPerCustomer: getSortedActiveContactArrayPerCustomer,
    customerLookup: getCustomerLookup,
    orderLookup: getOrderLookup,
    taskLookup: getTaskLookup,
    transportLogReportArray: getTransportLogReportArray,
    userUserProfileLookup: getUserUserProfileLookup,
  }),
  {},
)(ReportsCard);

interface EntryRowProps {
  disabled: boolean;
  entry: {
    action: "delivery" | "pickup";
    amount: number;
    area: number | null;
    customerName: string | null;
    distance: number | null;
    entry: Delivery | Pickup;
    location: string;
    locationUuid: string;
    note: string;
    place: string;
    relatedUnitUrl?: string | undefined;
    timestamp: string | null;
    unit: string;
  };
  haRequired: boolean;
  kmRequired: boolean;
  onDeleteClick?: (entry: Delivery | Pickup) => void;
  onEditClick?: (entry: Delivery | Pickup) => void;
}

class EntryRow extends PureComponent<EntryRowProps> {
  @bind
  handleDeleteClick(): void {
    const {entry, onDeleteClick} = this.props;
    if (onDeleteClick) {
      onDeleteClick(entry.entry);
    }
  }
  @bind
  handleEditClick(): void {
    const {entry, onEditClick} = this.props;
    if (onEditClick) {
      onEditClick(entry.entry);
    }
  }
  render(): React.JSX.Element {
    const {disabled, entry, haRequired, kmRequired} = this.props;
    const limitedSpace = bowser.mobile || kmRequired || haRequired;
    const location = limitedSpace ? entry.place : `${entry.place}, ${entry.location}`;
    return (
      <TableRow
        style={{
          borderBottomWidth: entry.note ? 0 : 1,
          cursor: disabled ? "default" : "pointer",
        }}
      >
        <TableCell onClick={this.handleEditClick} style={TIME_COLUMN_STYLE}>
          {formatTime(entry.timestamp || undefined)}
        </TableCell>
        <TableCell onClick={this.handleEditClick} style={AMOUNT_COLUMN_STYLE}>
          {entry.amount} {entry.unit}
        </TableCell>
        <TableCell onClick={this.handleEditClick}>{location}</TableCell>
        {kmRequired ? (
          <TableCell onClick={this.handleEditClick}>
            {entry.distance ? `${entry.distance} km` : ""}
          </TableCell>
        ) : null}
        {haRequired ? (
          <TableCell onClick={this.handleEditClick}>
            {entry.area ? `${entry.area} ha` : ""}
          </TableCell>
        ) : null}
        <TableCell style={{padding: 0, width: 48}}>
          <IconButton disabled={disabled} onClick={this.handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

function generatePickupEntryList(
  locationLookup: (url: LocationUrl) => Location | undefined,
  transportArray: readonly Pickup[],
  transportLocations: PickupLocation[],
  unit: string,
  relatedUnit: UnitUrl | null,
  handle: ((index: number) => void) | undefined,
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  customerSettings: Config,
  disabled: boolean,
): {
  blocks: React.JSX.Element[];
  entryData: PickupEntryData[];
} {
  const entryData: PickupEntryData[] = [];
  const blocks: React.JSX.Element[] = [];
  transportLocations.forEach((transportLocation, index) => {
    const identifier = `A${transportLocation.order + 1}`;
    const {address} = transportLocation;
    const transportLocationURL = transportLocation.url;
    const customerURL = transportLocation.customer;
    const customer = customerURL ? customerLookup(customerURL) : undefined;
    const customerName = customer ? customer.name : null;
    const locationUnit = transportLocation.unit;
    const locationRelatedUnit = transportLocation.relatedUnit;
    const locationUuid = urlToId(transportLocation.url);
    let transported = 0;
    let count = 0;
    const location = transportLocation.relatedLocation
      ? locationLookup(transportLocation.relatedLocation)
      : undefined;
    const addressString =
      getLocationButtonString(customerSettings.logButtonText, location) || address;
    transportArray.forEach((entry) => {
      if (entry.location !== transportLocationURL) {
        return;
      }
      count += 1;
      const amount = entry.amount || 0;
      entryData.push({
        action: "pickup",
        amount,
        area: null,
        customerName,
        distance: null,
        entry,
        location: addressString ?? "",
        locationUuid,
        note: entry.note || "",
        place: identifier,
        relatedUnitUrl: relatedUnit || locationRelatedUnit || undefined,
        timestamp: entry.deviceTimestamp,
        unit: unit || locationUnit || "",
      });
      transported += amount;
    });
    if (handle) {
      const decimalPlaces = customerSettings.transportLogDecimals;
      const roundedTransported = _.round(transported, decimalPlaces);
      const total = transportLocation.amount;
      let includeCount = false;
      includeCount = customerSettings.logPickupButtonDisplayCount;
      const countPart = includeCount && count ? ` (${count})` : "";
      const label = `${identifier}${countPart}, ${addressString}`;
      blocks.push(
        <div key={index}>
          <Button
            color="secondary"
            disabled={disabled}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => handle(index)}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
            variant="contained"
          >
            {label}
          </Button>
          {roundedTransported}/{total} {unit || locationUnit}
        </div>,
      );
    }
  });
  return {
    blocks,
    entryData,
  };
}

function generateDeliveryEntryList(
  locationLookup: (url: LocationUrl) => Location | undefined,
  transportArray: readonly Delivery[],
  transportLocations: DeliveryLocation[],
  unit: string,
  relatedUnit: UnitUrl | null,
  handle: ((index: number) => void) | undefined,
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  customerSettings: Config,
  disabled: boolean,
): {
  blocks: React.JSX.Element[];
  entryData: DeliveryEntryData[];
} {
  const entryData: DeliveryEntryData[] = [];
  const blocks: React.JSX.Element[] = [];
  transportLocations.forEach((transportLocation, index) => {
    const identifier = `L${transportLocation.order + 1}`;
    const {address} = transportLocation;
    const transportLocationURL = transportLocation.url;
    const customerURL = transportLocation.customer;
    const customer = customerURL ? customerLookup(customerURL) : undefined;
    const customerName = customer ? customer.name : null;
    const locationUnit = transportLocation.unit;
    const locationRelatedUnit = transportLocation.relatedUnit;
    const locationUuid = urlToId(transportLocation.url);
    let transported = 0;
    let count = 0;
    const location = transportLocation.relatedLocation
      ? locationLookup(transportLocation.relatedLocation)
      : undefined;
    const addressString =
      getLocationButtonString(customerSettings.logButtonText, location) || address;
    transportArray.forEach((entry) => {
      if (entry.location !== transportLocationURL) {
        return;
      }
      count += 1;
      const amount = entry.amount || 0;
      entryData.push({
        action: "delivery",
        amount,
        area: null,
        customerName,
        distance: null,
        entry,
        location: addressString ?? "",
        locationUuid,
        note: entry.note || "",
        place: identifier,
        relatedUnitUrl: relatedUnit || locationRelatedUnit || undefined,
        timestamp: entry.deviceTimestamp,
        unit: unit || locationUnit || "",
      });
      transported += amount;
    });
    if (handle) {
      const decimalPlaces = customerSettings.transportLogDecimals;
      const roundedTransported = _.round(transported, decimalPlaces);
      const total = transportLocation.amount;
      let includeCount = false;
      includeCount = customerSettings.logDeliveryButtonDisplayCount;
      const countPart = includeCount && count ? ` (${count})` : "";
      const label = `${identifier}${countPart}, ${addressString}`;

      blocks.push(
        <div key={index}>
          <Button
            color="secondary"
            disabled={disabled}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => handle(index)}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
            variant="contained"
          >
            {label}
          </Button>
          {roundedTransported}/{total} {unit || locationUnit}
        </div>,
      );
    }
  });
  return {
    blocks,
    entryData,
  };
}

const getTitleFromDeliveryData = (
  deliveryEntryData: DeliveryEntryData[],
  unitLookup: (url: UnitUrl) => Unit | undefined,
  taskLookup: (url: TaskUrl) => Task | undefined,
  transportLog: TransportLog,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  productLookup: (url: ProductUrl) => Product | undefined,
): string | null => {
  const units: string[] = [];
  if (!deliveryEntryData) {
    return null;
  }
  deliveryEntryData.forEach((entry) => {
    const entryRelatedUnit = entry.relatedUnitUrl ? unitLookup(entry.relatedUnitUrl) : undefined;
    const unitString = entryRelatedUnit?.name || "";
    if (!units.includes(unitString)) {
      units.push(unitString);
    }
  });

  const materialNames: string[] = [];

  const task = taskLookup(transportLog.task);
  if (task) {
    sortByOrderMember(Object.values(task.priceItemUses || {})).forEach((priceItemUse) => {
      const priceItemURL = priceItemUse.priceItem;
      const priceItem = priceItemLookup(priceItemURL);
      if (priceItem && units.includes(getUnitCode(priceItem, unitLookup))) {
        materialNames.push(priceItem.name);
      }
    });

    sortByOrderMember(Object.values(task.productUses || {})).forEach((productUse) => {
      const productURL = productUse.product;
      const product = productLookup(productURL);
      if (product && units.includes(getUnitCode(product, unitLookup))) {
        materialNames.push(product.name);
      }
    });
  }

  return materialNames.sort().join(", ");
};

interface TransportLogCardStateProps {
  currentRole: Role | null;
  currentUserURL: string | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  deliveryArray: readonly Delivery[];
  deliveryLocationArray: readonly DeliveryLocation[];
  deliveryLocationLookup: (url: DeliveryLocationUrl) => DeliveryLocation | undefined;
  locationLookup: (url: LocationUrl) => Location | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  pickupArray: readonly Pickup[];
  pickupLocationArray: readonly PickupLocation[];
  pickupLocationLookup: (url: PickupLocationUrl) => PickupLocation | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  shareToken: string | null;
  taskArray: readonly Task[];
  taskLookup: (url: TaskUrl) => Task | undefined;
  transportLogArray: readonly TransportLog[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface TransportLogCardDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  remove: (url: string) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface TransportLogCardOwnProps {
  completed: boolean;
  disabled: boolean;
  onEntryAdded: () => void;
  onRequestBuildReports: () => void;
  onRequestDeleteEntry: (entry: Delivery | Pickup) => void;
  task: Task;
  transportLog: TransportLog;
  userIsOtherMachineOperator: boolean;
  validated: boolean;
  workTypeString: string;
}

type TransportLogCardProps = TransportLogCardDispatchProps &
  TransportLogCardOwnProps &
  TransportLogCardStateProps;

interface TransportLogCardState {
  customerDialogCallback: ((customer: Customer) => void) | null;
  deleteDeliveryLocation: string | null;
  deletePickupLocation: string | null;
  deliveryDialogEdit: Delivery | null;
  deliveryDialogLocation: DeliveryLocation | null;
  deliveryLocationDialog: {
    amount: number | null;
    areaHa: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    open: boolean;
    relatedUnit: Unit | null;
    unit: string;
    url: DeliveryLocationUrl | null;
  };
  pickupDialogEdit: Pickup | null;
  pickupDialogLocation: PickupLocation | null;
  pickupLocationDialog: {
    amount: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    open: boolean;
    relatedUnit: Unit | null;
    unit: string;
    url: PickupLocationUrl | null;
  };
}

class TransportLogCard extends PureComponent<TransportLogCardProps, TransportLogCardState> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state: TransportLogCardState = {
    customerDialogCallback: null,
    deleteDeliveryLocation: null,
    deletePickupLocation: null,
    deliveryDialogEdit: null,
    deliveryDialogLocation: null,
    deliveryLocationDialog: {
      amount: null,
      areaHa: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      relatedUnit: null,
      unit: "",
      url: null,
    },
    pickupDialogEdit: null,
    pickupDialogLocation: null,
    pickupLocationDialog: {
      amount: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      relatedUnit: null,
      unit: "",
      url: null,
    },
  };
  @bind
  handleAddDeliveryLocation(): void {
    this.handleRequestDeliveryLocationDialog();
  }
  @bind
  handleAddPickupLocation(): void {
    this.handleRequestPickupLocationDialog();
  }
  @bind
  handleCustomerDialogCancel(): void {
    this.setState({customerDialogCallback: null});
  }

  @bind
  handleCustomerDialogOk(url: CustomerUrl | null): void {
    const callback = this.state.customerDialogCallback;
    this.setState({customerDialogCallback: null});
    const customer = url && this.props.customerLookup(url);
    if (callback && customer) {
      callback(customer);
    }
  }
  @bind
  handleDeleteDeliveryLocationCancel(): void {
    this.setState({deleteDeliveryLocation: null});
  }
  @bind
  handleDeleteDeliveryLocationOk(): void {
    const {deleteDeliveryLocation} = this.state;
    if (deleteDeliveryLocation) {
      this.setState({deleteDeliveryLocation: null});
      this.props.remove(deleteDeliveryLocation);
    }
  }
  @bind
  handleDeletePickupLocationCancel(): void {
    this.setState({deletePickupLocation: null});
  }
  @bind
  handleDeletePickupLocationOk(): void {
    const {deletePickupLocation} = this.state;
    if (deletePickupLocation) {
      this.setState({deletePickupLocation: null});
      this.props.remove(deletePickupLocation);
    }
  }
  @bind
  handleDeliveryDialogCancel(): void {
    this.setState({deliveryDialogEdit: null, deliveryDialogLocation: null});
  }

  @bind
  handleDeliveryDialogOk(params: {
    amount: number | null;
    areaHa: number | null;
    distanceKm: number | null;
    note: string;
    timestamp: string | null;
  }): void {
    const {amount, areaHa, distanceKm, note, timestamp} = params;
    const location = this.state.deliveryDialogLocation;
    const existing = this.state.deliveryDialogEdit;
    if (!location) {
      return;
    }
    this.setState({deliveryDialogEdit: null, deliveryDialogLocation: null});
    if (existing) {
      const delivery: Writable<Delivery> = {
        ...existing,
        amount,
        areaHa,
        distanceKm,
        note,
      };
      if (timestamp) {
        if (!existing.deviceTimestamp || !sameMinute(existing.deviceTimestamp, timestamp)) {
          delivery.deviceTimestamp = timestamp;
        }
      }
      const patch = computePatch(delivery, existing);
      if (patch) {
        this.props.update(existing.url, patch);
      }
    } else {
      const id = uuid();
      const url = instanceURL("delivery", id);
      const delivery = {
        amount,
        areaHa,
        deviceTimestamp: new Date().toISOString(),
        distanceKm,
        id,
        location: location.url,
        note,
        url,
      };
      this.props.create(delivery);
    }
    this.props.onEntryAdded();
  }
  @bind
  handleDeliveryLocationDelete(): void {
    const existingURL = this.state.deliveryLocationDialog.url;
    this.handleDeliveryLocationDialogCancel();
    this.setState({deleteDeliveryLocation: existingURL});
  }

  @bind
  handleDeliveryLocationDialogCancel(): void {
    const deliveryLocationDialog = {
      amount: null,
      areaHa: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      relatedUnit: null,
      unit: "",
      url: null,
    };
    this.setState({deliveryLocationDialog});
  }
  @bind
  handleDeliveryLocationDialogOk(params: {
    amount: number | null;
    areaHa: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    relatedUnit: Unit | null;
    unit: string;
  }): void {
    const {amount, areaHa, customer, location, note, relatedUnit, unit} = params;
    const existingURL = this.state.deliveryLocationDialog.url;
    if (existingURL) {
      const oldDeliveryLocation = this.props.deliveryLocationLookup(existingURL);
      if (!oldDeliveryLocation) {
        return;
      }
      const newDeliveryLocation: DeliveryLocation = {
        ...oldDeliveryLocation,
        amount,
        areaHa,
        customer: customer ? customer.url : null,
        note,
        relatedLocation: location ? location.url : null,
        relatedUnit: relatedUnit ? relatedUnit.url : null,
        unit: relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit,
      };
      const patch = computePatch(newDeliveryLocation, oldDeliveryLocation);
      if (patch) {
        this.props.update(oldDeliveryLocation.url, patch);
      }
    } else {
      const id = uuid();
      const url = instanceURL("deliveryLocation", id);
      const {task} = this.props;
      const taskURL = task.url;
      const transportLog = this.props.transportLogArray.find((t) => t.task === taskURL);
      if (!transportLog) {
        return;
      }
      const transportLogURL = transportLog.url;
      const order = this.props.deliveryLocationArray.filter(
        (d) => d.transportlog === transportLogURL,
      ).length;
      const newDeliveryLocation: DeliveryLocation = {
        amount,
        areaHa,
        customer: customer ? customer.url : null,
        id,
        note,
        order,
        relatedLocation: location ? location.url : null,
        relatedUnit: relatedUnit ? relatedUnit.url : null,
        task: null,
        transportlog: transportLogURL,
        unit: relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit,
        url,
      };
      this.props.create(newDeliveryLocation);
    }
    this.handleDeliveryLocationDialogCancel();
  }
  @bind
  handleDoDelivery(index: number): void {
    const {deliveryLocationArray, transportLog} = this.props;
    const transportLogURL = transportLog.url;
    const deliveryLocations = _.sortBy(
      deliveryLocationArray.filter((p) => p.transportlog === transportLogURL),
      (p) => p.order,
    );
    const deliveryLocation = deliveryLocations[index];
    this.handleRequestDeliveryDialog(deliveryLocation);
  }
  @bind
  handleDoPickup(index: number): void {
    const {pickupLocationArray, transportLog} = this.props;
    const transportLogURL = transportLog.url;
    const pickupLocations = _.sortBy(
      pickupLocationArray.filter((p) => p.transportlog === transportLogURL),
      (p) => p.order,
    );
    const pickupLocation = pickupLocations[index];
    this.handleRequestPickupDialog(pickupLocation);
  }
  @bind
  handlePickupDialogCancel(): void {
    this.setState({pickupDialogEdit: null, pickupDialogLocation: null});
  }
  @bind
  handlePickupDialogOk(params: {
    amount: number | null;
    note: string;
    timestamp: string | null;
  }): void {
    const {amount, note, timestamp} = params;
    const location = this.state.pickupDialogLocation;
    const existing = this.state.pickupDialogEdit;
    if (!location) {
      return;
    }
    this.setState({pickupDialogEdit: null, pickupDialogLocation: null});
    if (existing) {
      const pickup: Writable<Pickup> = {...existing, amount, note};
      if (timestamp) {
        if (!existing.deviceTimestamp || !sameMinute(existing.deviceTimestamp, timestamp)) {
          pickup.deviceTimestamp = timestamp;
        }
      }
      const patch = computePatch(pickup, existing);
      if (patch) {
        this.props.update(existing.url, patch);
      }
    } else {
      const id = uuid();
      const url = instanceURL("pickup", id);
      const pickup = {
        amount,
        deviceTimestamp: new Date().toISOString(),
        id,
        location: location.url,
        note,
        url,
      };
      this.props.create(pickup);
    }
  }
  @bind
  handlePickupLocationDelete(): void {
    const existingURL = this.state.pickupLocationDialog.url;
    this.handlePickupLocationDialogCancel();
    this.setState({deletePickupLocation: existingURL});
  }
  @bind
  handlePickupLocationDialogCancel(): void {
    const pickupLocationDialog = {
      address: "",
      amount: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      relatedUnit: null,
      unit: "",
      url: null,
      workplace: null,
    };
    this.setState({pickupLocationDialog});
  }
  @bind
  handlePickupLocationDialogOk(params: {
    amount: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    relatedUnit: Unit | null;
    unit: string;
  }): void {
    const {amount, customer, location, note, relatedUnit, unit} = params;
    const existingURL = this.state.pickupLocationDialog.url;
    const {task} = this.props;
    const taskURL = task.url;
    if (existingURL) {
      const oldPickupLocation = this.props.pickupLocationLookup(existingURL);
      if (!oldPickupLocation) {
        return;
      }
      const newPickupLocation: PickupLocation = {
        ...oldPickupLocation,
        amount,
        customer: customer ? customer.url : null,
        note,
        relatedLocation: location ? location.url : null,
        relatedUnit: relatedUnit ? relatedUnit.url : null,
        unit: relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit,
      };
      const patch = computePatch(newPickupLocation, oldPickupLocation);
      if (patch) {
        this.props.update(oldPickupLocation.url, patch);
      }
    } else {
      const id = uuid();
      const url = instanceURL("pickupLocation", id);
      const transportLog = this.props.transportLogArray.find((t) => t.task === taskURL);
      if (!transportLog) {
        return;
      }
      const transportLogURL = transportLog.url;
      const order = this.props.pickupLocationArray.filter(
        (d) => d.transportlog === transportLogURL,
      ).length;
      const newPickupLocation: PickupLocation = {
        amount,
        customer: customer ? customer.url : null,
        id,
        note,
        order,
        relatedLocation: location ? location.url : null,
        relatedUnit: relatedUnit ? relatedUnit.url : null,
        task: null,
        transportlog: transportLogURL,
        unit: relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit,
        url,
      };
      this.props.create(newPickupLocation);
    }
    this.handlePickupLocationDialogCancel();
    this.props.onEntryAdded();
  }
  @bind
  handleRequestCustomerDialog(callback: (customer: Customer) => void): void {
    this.setState({customerDialogCallback: callback});
  }
  @bind
  handleRequestDeleteEntry(entry: Delivery | Pickup): void {
    this.props.onRequestDeleteEntry(entry);
  }
  @bind
  handleRequestDeliveryDialog(deliveryLocation: DeliveryLocation): void {
    this.setState({deliveryDialogLocation: deliveryLocation});
  }
  @bind
  handleRequestDeliveryLocationDialog(deliveryLocation?: DeliveryLocation): void {
    if (deliveryLocation) {
      const deliveryLocationDialog = {
        amount: deliveryLocation.amount,
        areaHa: deliveryLocation.areaHa,
        customer:
          (deliveryLocation.customer && this.props.customerLookup(deliveryLocation.customer)) ||
          null,
        location:
          (deliveryLocation.relatedLocation &&
            this.props.locationLookup(deliveryLocation.relatedLocation)) ||
          null,
        note: deliveryLocation.note,
        open: true,
        relatedUnit:
          (deliveryLocation.relatedUnit && this.props.unitLookup(deliveryLocation.relatedUnit)) ||
          null,
        unit: deliveryLocation.unit,
        url: deliveryLocation.url,
      };
      this.setState({deliveryDialogLocation: null, deliveryLocationDialog});
    } else {
      const {task} = this.props;
      const orderURL = task && task.order;
      const order = orderURL ? this.props.orderLookup(orderURL) : null;
      const customerURL = order && order.customer;
      const customer = (customerURL && this.props.customerLookup(customerURL)) || null;
      const deliveryLocationDialog = {
        amount: null,
        areaHa: null,
        customer,
        location: null,
        note: "",
        open: true,
        relatedUnit: null,
        unit: "",
        url: null,
      };
      this.setState({deliveryDialogLocation: null, deliveryLocationDialog});
    }
  }
  @bind
  handleRequestEditEntry(entry: Delivery | Pickup): void {
    if (this.props.disabled) {
      return;
    }
    const locationURL = entry.location;
    const locationType = resourceNameFor(locationURL);
    if (locationType === "pickupLocation") {
      this.setState({
        pickupDialogEdit: entry as Pickup,
        pickupDialogLocation:
          this.props.pickupLocationLookup(locationURL as PickupLocationUrl) || null,
      });
    } else if (locationType === "deliveryLocation") {
      this.setState({
        deliveryDialogEdit: entry as Delivery,
        deliveryDialogLocation:
          this.props.deliveryLocationLookup(locationURL as DeliveryLocationUrl) || null,
      });
    }
  }
  @bind
  handleRequestPickupDialog(pickupLocation: PickupLocation): void {
    this.setState({pickupDialogLocation: pickupLocation});
  }
  @bind
  handleRequestPickupLocationDialog(pickupLocation?: PickupLocation): void {
    if (pickupLocation) {
      const pickupLocationDialog = {
        amount: pickupLocation.amount,
        customer:
          (pickupLocation.customer && this.props.customerLookup(pickupLocation.customer)) || null,
        location:
          (pickupLocation.relatedLocation &&
            this.props.locationLookup(pickupLocation.relatedLocation)) ||
          null,
        note: pickupLocation.note,
        open: true,
        relatedUnit:
          (pickupLocation.relatedUnit && this.props.unitLookup(pickupLocation.relatedUnit)) || null,
        unit: pickupLocation.unit,
        url: pickupLocation.url,
      };
      this.setState({pickupDialogLocation: null, pickupLocationDialog});
    } else {
      const {task} = this.props;
      const orderURL = task && task.order;
      const order = orderURL ? this.props.orderLookup(orderURL) : null;
      const customerURL = order && order.customer;
      const customer = (customerURL && this.props.customerLookup(customerURL)) || null;
      const pickupLocationDialog = {
        amount: null,
        customer,
        location: null,
        note: "",
        open: true,
        relatedUnit: null,
        unit: "",
        url: null,
      };
      this.setState({pickupDialogLocation: null, pickupLocationDialog});
    }
  }

  @bind
  handleTransferTotals(): void {
    const {customerSettings} = this.props;
    const {
      customerLookup,
      deliveryArray,
      deliveryLocationArray,
      locationLookup,
      priceItemLookup,
      productLookup,
      taskLookup,
      transportLog,
      unitLookup,
      update,
    } = this.props;

    const {relatedUnit, unit} = transportLog;
    const transportLogURL = transportLog.url;
    const task = taskLookup(transportLog.task);
    if (!task) {
      return;
    }

    const deliveryLocations = _.sortBy(
      deliveryLocationArray.filter((p) => p.transportlog === transportLogURL),
      (p) => p.order,
    );

    const deliveryEntryData = generateDeliveryEntryList(
      locationLookup,
      deliveryArray,
      deliveryLocations,
      unit,
      relatedUnit,
      this.handleDoDelivery,
      customerLookup,
      customerSettings,
      this.props.disabled,
    ).entryData;

    const taskTotals = new Map<string, number>();
    deliveryEntryData.forEach((entry) => {
      const entryRelatedUnit = entry.relatedUnitUrl && unitLookup(entry.relatedUnitUrl);
      const unitString = entryRelatedUnit ? entryRelatedUnit.name : entry.unit;
      taskTotals.set(unitString, (taskTotals.get(unitString) || 0) + entry.amount);
    });

    const decimalPlaces = customerSettings.materialDecimals;

    const patch: PatchOperation<Task>[] = [];

    _.sortBy(
      Object.entries(task.priceItemUses || {}),
      ([_identifier, priceItemUse]) => priceItemUse.order,
    ).forEach(([identifier, priceItemUse]) => {
      if (priceItemUse.count != null) {
        return;
      }
      const priceItemURL = priceItemUse.priceItem;
      const priceItem = priceItemLookup(priceItemURL);
      const priceItemRelatedUnit =
        priceItem && priceItem.relatedUnit && unitLookup(priceItem.relatedUnit);
      const unitString = priceItemRelatedUnit
        ? priceItemRelatedUnit.name
        : priceItem && getUnitString(priceItem, unitLookup);
      const newValue = unitString && taskTotals.get(unitString);
      if (newValue) {
        const patchOperation: PatchOperation<Task> = {
          path: ["priceItemUses", identifier, "count"],
          value: _.round(newValue, decimalPlaces),
        };
        patch.push(patchOperation);
      }
    });

    _.sortBy(
      Object.entries(task.productUses || {}),
      ([_identifier, productUse]) => productUse.order,
    ).forEach(([identifier, productUse]) => {
      if (productUse.count != null) {
        return;
      }
      const productURL = productUse.product;
      const product = productLookup(productURL);
      const productRelatedUnit = product && product.relatedUnit && unitLookup(product.relatedUnit);
      const unitString = productRelatedUnit
        ? productRelatedUnit.name
        : product && getUnitString(product, unitLookup);
      const newValue = unitString && taskTotals.get(unitString);
      if (newValue) {
        const patchOperation: PatchOperation<Task> = {
          path: ["productUses", identifier, "count"],
          value: _.round(newValue, decimalPlaces),
        };
        patch.push(patchOperation);
      }
    });

    if (patch.length) {
      update(task.url, patch);
    }
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;
    const {
      completed,
      customerLookup,
      deliveryArray,
      deliveryLocationArray,
      disabled,
      locationLookup,
      pickupArray,
      pickupLocationArray,
      priceItemLookup,
      productLookup,
      taskArray,
      taskLookup,
      transportLog,
      transportLogArray,
      unitLookup,
      workTypeString,
    } = this.props;
    const task = taskLookup(transportLog.task);
    const {unit} = transportLog;
    const {relatedUnit} = transportLog;
    const transportLogURL = transportLog.url;
    const pickupLocations = _.sortBy(
      pickupLocationArray.filter((p) => p.transportlog === transportLogURL),
      (p) => p.order,
    );
    const deliveryLocations = _.sortBy(
      deliveryLocationArray.filter((p) => p.transportlog === transportLogURL),
      (p) => p.order,
    );

    const pickupEntryData = generatePickupEntryList(
      locationLookup,
      pickupArray,
      pickupLocations,
      unit,
      relatedUnit,
      this.handleDoPickup,
      customerLookup,
      customerSettings,
      this.props.disabled,
    );

    const pickupLocationBlocks = pickupEntryData.blocks;

    const deliveryEntryData = generateDeliveryEntryList(
      locationLookup,
      deliveryArray,
      deliveryLocations,
      unit,
      relatedUnit,
      this.handleDoDelivery,
      customerLookup,
      customerSettings,
      this.props.disabled,
    );

    const entryData = (pickupEntryData.entryData as (DeliveryEntryData | PickupEntryData)[]).concat(
      deliveryEntryData.entryData,
    );
    const deliveryLocationBlocks = deliveryEntryData.blocks;

    const orderTotals = new Map<
      string,
      {
        delivery: number;
        difference: number;
        pickup: number;
        unit: string;
      }
    >();

    if (customerSettings.sharedTransportLog) {
      const orderUrl = task && task.order;
      const taskUrls = new Set(taskArray.filter((t) => t.order === orderUrl).map((t) => t.url));
      const orderTransportLogs = transportLogArray.filter((t) => taskUrls.has(t.task));

      const orderEntryData: (DeliveryEntryData | PickupEntryData)[] = [];
      orderTransportLogs.forEach((orderTransportLog) => {
        const orderUnit = orderTransportLog.unit;
        const orderRelatedUnit = orderTransportLog.relatedUnit;
        const orderTransportLogURL = orderTransportLog.url;
        const orderPickupLocations = _.sortBy(
          pickupLocationArray.filter((p) => p.transportlog === orderTransportLogURL),
          (p) => p.order,
        );
        const orderDeliveryLocations = _.sortBy(
          deliveryLocationArray.filter((p) => p.transportlog === orderTransportLogURL),
          (p) => p.order,
        );

        const orderPickupEntryData = generatePickupEntryList(
          locationLookup,
          pickupArray,
          orderPickupLocations,
          orderUnit,
          orderRelatedUnit,
          undefined,
          customerLookup,
          customerSettings,
          this.props.disabled,
        );
        orderEntryData.push(...orderPickupEntryData.entryData);

        const orderDeliveryEntryData = generateDeliveryEntryList(
          locationLookup,
          deliveryArray,
          orderDeliveryLocations,
          orderUnit,
          orderRelatedUnit,
          undefined,
          customerLookup,
          customerSettings,
          this.props.disabled,
        );
        Array.prototype.push.apply(orderEntryData, orderDeliveryEntryData.entryData);
      });

      orderEntryData.forEach((entry) => {
        const entryRelatedUnit = entry.relatedUnitUrl && unitLookup(entry.relatedUnitUrl);
        const unitString = entryRelatedUnit
          ? entryRelatedUnit.symbol || entryRelatedUnit.name
          : entry.unit;

        const {amount} = entry;
        const existing = orderTotals.get(unitString);
        if (existing) {
          if (entry.action === "pickup") {
            existing.pickup += amount;
            existing.difference += amount;
          } else {
            existing.delivery += amount;
            existing.difference -= amount;
          }
        } else {
          const newEntry = {
            delivery: 0,
            difference: 0,
            pickup: 0,
            unit: unitString,
          };
          if (entry.action === "pickup") {
            newEntry.pickup += amount;
            newEntry.difference += amount;
          } else {
            newEntry.delivery += amount;
            newEntry.difference -= amount;
          }
          orderTotals.set(unitString, newEntry);
        }
      });
    }
    entryData.sort((entryA, entryB) => {
      // newest first
      if (entryA.timestamp && entryB.timestamp) {
        return new Date(entryB.timestamp).valueOf() - new Date(entryA.timestamp).valueOf();
      }
      return 0;
    });
    const entries: React.JSX.Element[] = [];
    const {haRequired, kmRequired} = transportLog;

    const columns = 4 + (kmRequired ? 1 : 0) + (haRequired ? 1 : 0);

    const taskTotals = new Map<
      string,
      {
        delivery: number;
        difference: number;
        pickup: number;
        unit: string;
      }
    >();
    entryData.forEach((entry, index) => {
      entries.push(
        <EntryRow
          disabled={disabled}
          entry={entry}
          haRequired={haRequired}
          key={index}
          kmRequired={kmRequired}
          onDeleteClick={this.handleRequestDeleteEntry}
          onEditClick={this.handleRequestEditEntry}
        />,
      );

      if (customerSettings.sharedTransportLog) {
        const entryRelatedUnit = entry.relatedUnitUrl && unitLookup(entry.relatedUnitUrl);
        const unitString = entryRelatedUnit
          ? entryRelatedUnit.symbol || entryRelatedUnit.name
          : entry.unit;

        const existing = taskTotals.get(unitString);
        const {amount} = entry;
        if (existing) {
          if (entry.action === "pickup") {
            existing.pickup += amount;
            existing.difference += amount;
          } else {
            existing.delivery += amount;
            existing.difference -= amount;
          }
        } else {
          const newEntry = {
            delivery: 0,
            difference: 0,
            pickup: 0,
            unit: unitString,
          };
          if (entry.action === "pickup") {
            newEntry.pickup += amount;
            newEntry.difference += amount;
          } else {
            newEntry.delivery += amount;
            newEntry.difference -= amount;
          }
          taskTotals.set(unitString, newEntry);
        }
      }
      if (entry.note) {
        const editEntry = (): void => this.handleRequestEditEntry(entry.entry);
        entries.push(
          <TableRow
            key={`${index}-note`}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={editEntry}
            style={{height: 36}}
          >
            <TableCell colSpan={columns - 1} style={{height: 24, verticalAlign: "top"}}>
              <em>{entry.note}</em>
            </TableCell>
            <TableCell style={{height: 24}} />
          </TableRow>,
        );
      }
    });

    const {currentUserURL} = this.props;
    const role = this.props.currentRole;
    const isManager = role && role.manager;
    let reportsCard = null;
    if (completed && isManager) {
      reportsCard = (
        <ConnectedReportsCard
          amountUnit={unit}
          currentUserURL={currentUserURL}
          customerSettings={this.props.customerSettings}
          entryData={entryData}
          onRequestBuildReports={this.props.onRequestBuildReports}
          shareToken={this.props.shareToken}
          transportLog={transportLog}
          workTypeString={workTypeString}
        />
      );
    }
    let titleBlock;
    if (customerSettings.showHeaderOnTransportLog && !bowser.mobile) {
      const title = getTitleFromDeliveryData(
        deliveryEntryData.entryData,
        unitLookup,
        taskLookup,
        transportLog,
        priceItemLookup,
        productLookup,
      );
      if (title) {
        titleBlock = <CardHeader title={title} />;
      }
    }

    let canDeleteDeliveryLocation = !this.props.userIsOtherMachineOperator;
    if (canDeleteDeliveryLocation && this.state.deliveryLocationDialog.url) {
      const deliveryLocationURL = this.state.deliveryLocationDialog.url;
      canDeleteDeliveryLocation = this.props.deliveryArray.every(
        (delivery) => delivery.location !== deliveryLocationURL,
      );
    }
    let canDeletePickupLocation = !this.props.userIsOtherMachineOperator;
    if (canDeletePickupLocation && this.state.pickupLocationDialog.url) {
      const pickupLocationURL = this.state.pickupLocationDialog.url;
      canDeletePickupLocation = this.props.pickupArray.every(
        (pickup) => pickup.location !== pickupLocationURL,
      );
    }

    let deliveryDialogAmount;
    let deliveryDialogDistanceKm;
    if (this.state.deliveryDialogEdit) {
      deliveryDialogAmount = this.state.deliveryDialogEdit.amount;
      deliveryDialogDistanceKm = this.state.deliveryDialogEdit.distanceKm;
    } else if (transportLog && this.state.deliveryDialogLocation) {
      const pickupLocationURLSet = new Set(
        this.props.pickupLocationArray
          .filter((p) => p.transportlog === transportLogURL)
          .map((p) => p.url),
      );
      const deliveryLocationURLSet = new Set(
        this.props.deliveryLocationArray
          .filter((d) => d.transportlog === transportLogURL)
          .map((d) => d.url),
      );
      const pickupList = _.sortBy(
        this.props.pickupArray.filter((p) => pickupLocationURLSet.has(p.location)),
        (p: Pickup) =>
          p.deviceTimestamp
            ? getNormalisedDeviceTimestamp(
                p as {
                  readonly deviceTimestamp: string;
                },
              )
            : "",
      );
      const deliveryList = _.sortBy(
        this.props.deliveryArray.filter((d) => deliveryLocationURLSet.has(d.location)),
        (d: Delivery) =>
          d.deviceTimestamp
            ? getNormalisedDeviceTimestamp(
                d as {
                  readonly deviceTimestamp: string;
                },
              )
            : "",
      );
      const pickupSum = pickupList.reduce((acc, pickup) => {
        return acc + (pickup.amount || 0);
      }, 0);
      const deliverySum = deliveryList.reduce((acc, delivery) => {
        return acc + (delivery.amount || 0);
      }, 0);

      const decimals = 2;
      deliveryDialogAmount = _.round(pickupSum - deliverySum, decimals);
      const entryList = _.sortBy(
        (pickupList as (Delivery | Pickup)[]).concat(deliveryList),
        (entry: Delivery | Pickup) =>
          entry.deviceTimestamp
            ? getNormalisedDeviceTimestamp(
                entry as {
                  readonly deviceTimestamp: string;
                },
              )
            : "",
      );
      const lastEntry = entryList[entryList.length - 1];
      const lastLocation = lastEntry ? lastEntry.location : null;
      entryList.forEach((entry, index) => {
        if (
          !this.state.deliveryDialogLocation ||
          entry.location !== this.state.deliveryDialogLocation.url
        ) {
          return;
        }
        const deliveryEntry = entry as Delivery;
        const previous = entryList[index - 1];
        if (previous && previous.location === lastLocation) {
          deliveryDialogDistanceKm = deliveryEntry.distanceKm;
        }
      });
    }
    const deliveryDialogAreaHa = this.state.deliveryDialogEdit
      ? this.state.deliveryDialogEdit.areaHa
      : undefined;
    const deliveryDialogCustomer = this.state.deliveryDialogLocation?.customer
      ? this.props.customerLookup(this.state.deliveryDialogLocation.customer)
      : undefined;
    const deliveryDialogNote = this.state.deliveryDialogEdit
      ? this.state.deliveryDialogEdit.note
      : undefined;
    let pickupDialogAmount;
    if (this.state.pickupDialogEdit) {
      pickupDialogAmount = this.state.pickupDialogEdit.amount;
    } else if (transportLog && this.state.pickupDialogLocation) {
      const pickupLocationURLSet = new Set(
        this.props.pickupLocationArray
          .filter((p) => p.transportlog === transportLogURL)
          .map((p) => p.url),
      );
      const lastPickup = _.maxBy(
        this.props.pickupArray.filter((p) => pickupLocationURLSet.has(p.location)),
        (p: Pickup) =>
          p.deviceTimestamp
            ? getNormalisedDeviceTimestamp(
                p as {
                  readonly deviceTimestamp: string;
                },
              )
            : "",
      );
      if (lastPickup) {
        pickupDialogAmount = lastPickup.amount;
      } else {
        pickupDialogAmount = transportLog.amountPerTrip;
      }
    }

    const pickupDialogCustomer = this.state.pickupDialogLocation?.customer
      ? this.props.customerLookup(this.state.pickupDialogLocation.customer)
      : undefined;
    const pickupDialogNote = this.state.pickupDialogEdit
      ? this.state.pickupDialogEdit.note
      : undefined;

    return (
      <>
        <div>
          <Grid container>
            <Grid item xs>
              <Card style={{margin: "1em"}}>
                {titleBlock}
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item sm xs={12}>
                      <FormattedMessage
                        defaultMessage="Afhentning"
                        id="task-instance.header.pickup"
                        tagName="h3"
                      />
                      {pickupLocationBlocks}
                      <Button
                        color="primary"
                        disabled={disabled}
                        onClick={this.handleAddPickupLocation}
                        variant="contained"
                      >
                        {formatMessage(messages.addPickupLocation)}
                      </Button>
                    </Grid>
                    <Grid item sm xs={12}>
                      <FormattedMessage
                        defaultMessage="Levering"
                        id="task-instance.header.delivery"
                        tagName="h3"
                      />
                      {deliveryLocationBlocks}
                      <Button
                        color="primary"
                        disabled={disabled}
                        onClick={this.handleAddDeliveryLocation}
                        variant="contained"
                      >
                        {formatMessage(messages.addDeliveryLocation)}
                      </Button>
                    </Grid>
                  </Grid>
                  <div>
                    <div>
                      <FormattedMessage defaultMessage="Enhed:" id="task-instance.label.unit" />
                      &nbsp;
                      {transportLog.unit}
                    </div>
                    <div>
                      <FormattedMessage
                        defaultMessage="Forventet per tur:"
                        id="task-instance.label.expected-per-trip"
                      />
                      &nbsp;
                      {transportLog.amountPerTrip}
                    </div>
                  </div>
                  <Button
                    color="primary"
                    disabled={disabled}
                    onClick={this.handleTransferTotals}
                    variant="contained"
                  >
                    {customerSettings.materialUseAlternativeText
                      ? formatMessage(messages.alternativeTransferTotals)
                      : formatMessage(messages.transferTotals)}
                  </Button>
                </CardContent>
              </Card>
              {customerSettings.sharedTransportLog ? (
                <TransportLogTotalsCard
                  customerSettings={this.props.customerSettings}
                  orderTotals={orderTotals}
                  taskTotals={taskTotals}
                />
              ) : null}
              <Card style={{margin: "1em"}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={TIME_COLUMN_STYLE}>
                        <FormattedMessage
                          defaultMessage="Kl."
                          id="task-instance.table-header.time"
                        />
                      </TableCell>
                      <TableCell style={AMOUNT_COLUMN_STYLE}>
                        <FormattedMessage
                          defaultMessage="Mængde"
                          id="task-instance.table-header.amount"
                        />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage
                          defaultMessage="Sted"
                          id="task-instance.table-header.place"
                        />
                      </TableCell>
                      {transportLog.kmRequired ? (
                        <TableCell>
                          <FormattedMessage
                            defaultMessage="Transport"
                            id="task-instance.table-header.distance-km"
                          />
                        </TableCell>
                      ) : null}
                      {transportLog.haRequired ? (
                        <TableCell>
                          <FormattedMessage
                            defaultMessage="Areal"
                            id="task-instance.table-header.area"
                          />
                        </TableCell>
                      ) : null}
                      <TableCell style={{width: 48}} />
                    </TableRow>
                  </TableHead>
                  <TableBody style={{cursor: "pointer"}}>{entries}</TableBody>
                </Table>
              </Card>
              {reportsCard}
            </Grid>
          </Grid>
        </div>
        <PickupLocationDialog
          amount={this.state.pickupLocationDialog.amount ?? undefined}
          canDelete={canDeletePickupLocation}
          customer={this.state.pickupLocationDialog.customer || undefined}
          isNew={!this.state.pickupLocationDialog.url}
          location={this.state.pickupLocationDialog.location || undefined}
          note={this.state.pickupLocationDialog.note}
          onCancel={this.handlePickupLocationDialogCancel}
          onCustomerChanged={this.handleCustomerDialogOk}
          onOk={this.handlePickupLocationDialogOk}
          onRequestDelete={this.handlePickupLocationDelete}
          open={this.state.pickupLocationDialog.open}
          relatedUnit={
            transportLog
              ? (transportLog.relatedUnit && this.props.unitLookup(transportLog.relatedUnit)) ||
                this.state.pickupLocationDialog.relatedUnit ||
                undefined
              : undefined
          }
          unit={
            transportLog ? transportLog.unit || this.state.pickupLocationDialog.unit : undefined
          }
        />
        <PickupDialog
          amount={pickupDialogAmount ?? undefined}
          customer={pickupDialogCustomer}
          isNew={!this.state.pickupDialogEdit}
          note={pickupDialogNote}
          onCancel={this.handlePickupDialogCancel}
          onOk={this.handlePickupDialogOk}
          onRequestPickupLocationDialog={this.handleRequestPickupLocationDialog}
          open={!!this.state.pickupDialogLocation}
          pickupLocation={this.state.pickupDialogLocation || undefined}
          relatedUnit={
            transportLog
              ? (transportLog.relatedUnit && this.props.unitLookup(transportLog.relatedUnit)) ||
                (this.state.pickupDialogLocation?.relatedUnit &&
                  this.props.unitLookup(this.state.pickupDialogLocation.relatedUnit)) ||
                undefined
              : undefined
          }
          timestamp={this.state.pickupDialogEdit?.deviceTimestamp || undefined}
          unit={
            transportLog ? transportLog.unit || this.state.pickupDialogLocation?.unit : undefined
          }
        />
        <DeliveryLocationDialog
          amount={this.state.deliveryLocationDialog.amount ?? undefined}
          areaHa={this.state.deliveryLocationDialog.areaHa ?? undefined}
          canDelete={canDeleteDeliveryLocation}
          customer={this.state.deliveryLocationDialog.customer || undefined}
          customerSettings={this.props.customerSettings}
          haRequired={transportLog ? transportLog.haRequired : undefined}
          isNew={!this.state.deliveryLocationDialog.url}
          location={this.state.deliveryLocationDialog.location || undefined}
          note={this.state.deliveryLocationDialog.note}
          onCancel={this.handleDeliveryLocationDialogCancel}
          onOk={this.handleDeliveryLocationDialogOk}
          onRequestDelete={this.handleDeliveryLocationDelete}
          open={this.state.deliveryLocationDialog.open}
          relatedUnit={
            transportLog
              ? (transportLog.relatedUnit && this.props.unitLookup(transportLog.relatedUnit)) ||
                this.state.deliveryLocationDialog.relatedUnit ||
                undefined
              : undefined
          }
          unit={
            transportLog ? transportLog.unit || this.state.deliveryLocationDialog.unit : undefined
          }
        />
        <DeliveryDialog
          amount={deliveryDialogAmount ?? undefined}
          areaHa={deliveryDialogAreaHa ?? undefined}
          customer={deliveryDialogCustomer}
          deliveryLocation={this.state.deliveryDialogLocation || undefined}
          distanceKm={deliveryDialogDistanceKm ?? undefined}
          haRequired={transportLog ? transportLog.haRequired : undefined}
          isNew={!this.state.deliveryDialogEdit}
          kmRequired={transportLog ? transportLog.kmRequired : undefined}
          note={deliveryDialogNote}
          onCancel={this.handleDeliveryDialogCancel}
          onOk={this.handleDeliveryDialogOk}
          onRequestDeliveryLocationDialog={this.handleRequestDeliveryLocationDialog}
          open={!!this.state.deliveryDialogLocation}
          relatedUnit={
            transportLog
              ? (transportLog.relatedUnit && this.props.unitLookup(transportLog.relatedUnit)) ||
                (this.state.deliveryDialogLocation?.relatedUnit &&
                  this.props.unitLookup(this.state.deliveryDialogLocation.relatedUnit)) ||
                undefined
              : undefined
          }
          timestamp={this.state.deliveryDialogEdit?.deviceTimestamp || undefined}
          unit={transportLog?.unit || this.state.deliveryDialogLocation?.unit}
        />
        <DeleteDialog
          onCancel={this.handleDeleteDeliveryLocationCancel}
          onOk={this.handleDeleteDeliveryLocationOk}
          open={!!this.state.deleteDeliveryLocation}
        >
          <FormattedMessage
            defaultMessage="Slet leveringssted?"
            id="task-instance.label.delete-delivery-location"
          />
        </DeleteDialog>
        <DeleteDialog
          onCancel={this.handleDeletePickupLocationCancel}
          onOk={this.handleDeletePickupLocationOk}
          open={!!this.state.deletePickupLocation}
        >
          <FormattedMessage
            defaultMessage="Slet afhentningssted?"
            id="task-instance.label.delete-picup-location"
          />
        </DeleteDialog>
        <CustomerSelectCreateDialog
          onCancel={this.handleCustomerDialogCancel}
          onOk={this.handleCustomerDialogOk}
          open={!!this.state.customerDialogCallback}
        />
      </>
    );
  }
}

const ConnectedTransportLogCard: React.ComponentType<TransportLogCardOwnProps> = connect<
  TransportLogCardStateProps,
  TransportLogCardDispatchProps,
  TransportLogCardOwnProps,
  AppState
>(
  createStructuredSelector<AppState, TransportLogCardStateProps>({
    currentRole: getCurrentRole,
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    deliveryArray: getDeliveryArray,
    deliveryLocationArray: getDeliveryLocationArray,
    deliveryLocationLookup: getDeliveryLocationLookup,
    locationLookup: getLocationLookup,
    orderLookup: getOrderLookup,
    pickupArray: getPickupArray,
    pickupLocationArray: getPickupLocationArray,
    pickupLocationLookup: getPickupLocationLookup,
    priceItemLookup: getPriceItemLookup,
    productLookup: getProductLookup,
    shareToken: getShareToken,
    taskArray: getTaskArray,
    taskLookup: getTaskLookup,
    transportLogArray: getTransportLogArray,
    unitLookup: getUnitLookup,
  }),
  {
    create: actions.create,
    remove: actions.remove,
    update: actions.update,
  },
)(TransportLogCard);

export default ConnectedTransportLogCard;
