import {Config} from "@co-common-libs/config";
import {
  AccomodationAllowance,
  AnniversaryType,
  AnniversaryTypeUrl,
  Availability,
  CalendarWorkHours,
  Contact,
  ContactUrl,
  Culture,
  CultureUrl,
  Customer,
  CustomerUrl,
  DaysAbsence,
  DaysAbsenceUrl,
  EmployeeGroup,
  EmployeeGroupUrl,
  HoursAbsence,
  HoursAbsenceUrl,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductUrl,
  Project,
  ProjectUrl,
  Role,
  RoutePlan,
  RoutePlanUrl,
  Task,
  TaskUrl,
  Timer,
  TimerStart,
  TimerUrl,
  Unit,
  UnitUrl,
  urlToId,
  User,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp, getUserAnniversaryMap} from "@co-common-libs/resources-utils";
import {
  dateFromString,
  dateToString,
  defaultToEmptyString,
  getMidnights,
  WEEK_DAYS,
  WEEKDAY_SATURDAY,
  WEEKDAY_SUNDAY,
  weekNumber,
} from "@co-common-libs/utils";
import {Check, makeQuery, Query} from "@co-frontend-libs/db-resources";
import {
  actions,
  AppState,
  getAccomodationAllowanceArray,
  getAnniversaryTypeArray,
  getAnniversaryTypeLookup,
  getAvailabilityArray,
  getCalendarWorkHoursArray,
  getContactLookup,
  getCultureLookup,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getDaysAbsenceArray,
  getEmployeeGroupLookup,
  getFilteredMachineLookup,
  getHoursAbsenceArray,
  getLocationLookup,
  getOrderLookup,
  getPriceItemLookup,
  getProductLookup,
  getProjectLookup,
  getRoleArray,
  getRoutePlanLookup,
  getTaskArray,
  getTaskLookup,
  getTimerLookup,
  getTimerStartArray,
  getUnitLookup,
  getUserArray,
  getUserProfileArray,
  getUserUserProfileLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {ClickAwayListener, Theme, withTheme} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import {
  Calendar,
  DraggableTaskBlock,
  FILTER_BAR_HEIGHT,
  FilterBar,
  HALF_HOUR_ROW_HEIGHT,
  HOUR_COLUMN_WIDTH,
  HoursColumn,
  PARTIALLY_PLANNED_TASK_HEIGHT,
  TaskDragPreview,
  TaskInfoPopOver,
  USER_COLUMN_WIDTH,
} from "app-components";
import {
  generateTimerStartMapping,
  resolveTaskRelations,
  sortTasksInColumns,
  type TaskWithRelations,
} from "app-components";
import {
  computeIntervalsTruncated,
  machineOperatorCanSeeFutureTasksUntil,
  tasksForPeriod,
  tasksOverflowingToDate,
} from "app-utils";
import {bind} from "bind-decorator";
import ImmutableDate from "bloody-immutable-date";
import bowser from "bowser";
import {endOfDay, startOfDay, subDays} from "date-fns";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {WeekDay} from "../types";
import {PlannedLineDrop} from "./planned-line";
import {UsersHeaderBlock} from "./users-header-block";

const SCROLL_DELAY_MS = 20;
const SCROLL_PIXELS_ADJUSTER = 2;
const SCROLL_ZONE_HEIGHT = 50;

const messages = defineMessages({
  noUsersWithTasks: {
    defaultMessage: "Der er ingen medarbejdere med opgaver i dag",
    id: "task-calendar.no-users-with-tasks",
  },
  tasksWithoutDateChauffeur: {
    defaultMessage: "Opgaver uden dato eller uden chauffør",
    id: "task-calendar.tasks-without-date-or-chauffeur",
  },
  tasksWithoutDateEmployee: {
    defaultMessage: "Opgaver uden dato eller uden medarbejder",
    id: "task-calendar.tasks-without-date-or-employee",
  },
  tasksWithoutDateMachineOperator: {
    defaultMessage: "Opgaver uden dato eller uden maskinfører",
    id: "task-calendar.tasks-without-date-or-machine-operator",
  },
});

const ARCHIVE_DAYS = 7;

const WEEKDAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;

export function getAbsenceLists(
  customerSettings: Config,
  selectedDate: string,
  calendarStartTime: string,
  calendarEndTime: string,
  daysAbsenceArray: readonly DaysAbsence[],
  hoursAbsenceArray: readonly HoursAbsence[],
): {
  absentEmployeeURLSet: Set<string>;
  daysAbsenceIntersectingDayList: DaysAbsence[];
  hoursAbsenceIntersectingDayList: HoursAbsence[];
} {
  let daysAbsenceIntersectingDayList: DaysAbsence[] | undefined;
  let hoursAbsenceIntersectingDayList: HoursAbsence[] | undefined;
  const absentEmployeeURLSet = new Set<string>();

  if (customerSettings.registerAbsence) {
    daysAbsenceIntersectingDayList = _.sortBy(
      daysAbsenceArray.filter((d) => d.fromDate <= selectedDate && d.toDate >= selectedDate),
      (d) => d.fromDate + d.toDate + d.absenceType,
    );
    daysAbsenceIntersectingDayList.forEach((absence) => {
      absentEmployeeURLSet.add(absence.user);
    });
    hoursAbsenceIntersectingDayList = _.sortBy(
      hoursAbsenceArray.filter(
        (h) => h.fromTimestamp <= calendarEndTime && h.toTimestamp >= calendarStartTime,
      ),
      (h) => h.fromTimestamp + h.toTimestamp + h.absenceType,
    );
    hoursAbsenceIntersectingDayList.forEach((absence) => {
      absentEmployeeURLSet.add(absence.user);
    });
  } else {
    daysAbsenceIntersectingDayList = [];
    hoursAbsenceIntersectingDayList = [];
  }
  return {
    absentEmployeeURLSet,
    daysAbsenceIntersectingDayList,
    hoursAbsenceIntersectingDayList,
  };
}

const HALF_HOUR_MINUTES = 30;

const calculateMinutePosition = (minutesSinceMidnight: number): number => {
  return Math.round(minutesSinceMidnight * (HALF_HOUR_ROW_HEIGHT / HALF_HOUR_MINUTES));
};

const TEMPORARY_QUERIES_KEY = "EmployeeTabContent";

interface EmployeeTabContentStateProps {
  accomodationAllowanceArray: readonly AccomodationAllowance[];
  anniversaryTypeArray: readonly AnniversaryType[];
  anniversaryTypeLookup: (url: AnniversaryTypeUrl) => AnniversaryType | undefined;
  availabilityArray: readonly Availability[];
  calendarWorkHoursArray: readonly CalendarWorkHours[];
  contactLookup: (url: ContactUrl) => Contact | undefined;
  cultureLookup: (url: CultureUrl) => Culture | undefined;
  currentUserURL: string | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  daysAbsenceArray: readonly DaysAbsence[];
  employeeGroupLookup: (url: EmployeeGroupUrl) => EmployeeGroup | undefined;
  hoursAbsenceArray: readonly HoursAbsence[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  roleArray: readonly Role[];
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined;
  taskArray: readonly Task[];
  taskLookup: (url: TaskUrl) => Task | undefined;
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  userArray: readonly User[];
  userProfileArray: readonly UserProfile[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeArray: readonly WorkType[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface EmployeeTabContentDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  putQueryKeys: (
    update: {readonly [key: string]: string | undefined},
    navigationKind?: PartialNavigationKind,
  ) => void;
}

interface EmployeeTabContentOwnProps {
  dndMode: boolean;
  filterString: string;
  nowMinutes?: number | undefined;
  onAbsenceClick: (url: DaysAbsenceUrl | HoursAbsenceUrl) => void;
  onAvailabilityChange: (options: {
    currentValue: boolean | undefined;
    user: User;
    userProfile: UserProfile;
    weekday: WeekDay;
    weekNumber: number;
    year: number;
  }) => void;
  onRequestFilterClear: () => void;
  onRequestOrdering: (ordering: readonly string[]) => void;
  ordering?: string[];
  pathName: string;
  scrollX: string;
  scrollY: string;
  selectedDate: string;
  selectedDepartmentIdentifierSet: ReadonlySet<string>;
  selectedEmployeeGroupUrlSet: ReadonlySet<string>;
  selectedWorkTypeURLSet: ReadonlySet<string>;
  temporaryQueriesDiscardedForPath: (pathName: string, key: string) => void;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
  theme: Theme;
  userIsManager: boolean;
  userIsSeniorMachineOperator: boolean;
}

interface EmployeeTabContentState {
  taskInfo: {
    position: {x: number; y: number};
    task: Task;
  } | null;
}
type EmployeeTabContentProps = EmployeeTabContentDispatchProps &
  EmployeeTabContentOwnProps &
  EmployeeTabContentStateProps;

class EmployeeTabContent extends React.Component<EmployeeTabContentProps, EmployeeTabContentState> {
  static contextType = IntlContext;
  _calendarContainerRef = React.createRef<HTMLDivElement>();
  _calendarScrollContainerRef = React.createRef<HTMLDivElement>();
  _groupHeaderRef = React.createRef<HTMLDivElement>();
  _horizontalScrollTimeout: number | undefined = undefined;
  _hoursColumnRef = React.createRef<HTMLDivElement>();
  _scrollTimeout: number | undefined = undefined;
  _usersHeaderRef = React.createRef<HTMLDivElement>();
  context!: React.ContextType<typeof IntlContext>;
  state: EmployeeTabContentState = {
    taskInfo: null,
  };
  updateScrollQuery: _.DebouncedFunc<(left: number, top: number) => void>;
  constructor(props: EmployeeTabContentProps) {
    super(props);
    this.updateScrollQuery = _.debounce((left, top) => {
      const scrollX = `${left}`;
      const scrollY = `${top}`;
      this.props.putQueryKeys({
        scrollX,
        scrollY,
      });
    }, 200);
  }
  componentDidMount(): void {
    this.scrollToNow();
    if (this._calendarContainerRef.current) {
      this._calendarContainerRef.current.addEventListener("touchmove", this.handleTouchMove, false);
      this._calendarContainerRef.current.addEventListener("touchend", this.handleTouchEnd);
    }
    const scrollNode = this._calendarScrollContainerRef.current;
    if (scrollNode) {
      scrollNode.addEventListener("scroll", this.handleCalendarScroll);
      const {scrollX, scrollY} = this.props;
      if (scrollX) {
        const left = parseFloat(scrollX);
        if (!isNaN(left)) {
          scrollNode.scrollLeft = left;
        }
      }
      if (scrollY) {
        const top = parseFloat(scrollY);
        if (!isNaN(top)) {
          scrollNode.scrollTop = top;
        }
      }
    }
  }
  componentWillUnmount(): void {
    const scrollNode = this._calendarScrollContainerRef.current;
    if (scrollNode) {
      scrollNode.removeEventListener("scroll", this.handleCalendarScroll);
    }
    if (this._calendarContainerRef.current) {
      this._calendarContainerRef.current.removeEventListener(
        "touchmove",
        this.handleTouchMove,
        false,
      );
      this._calendarContainerRef.current.removeEventListener("touchend", this.handleTouchEnd);
    }
    const {pathName, temporaryQueriesDiscardedForPath} = this.props;
    temporaryQueriesDiscardedForPath(pathName, TEMPORARY_QUERIES_KEY);
    this.updateScrollQuery.cancel();
  }
  getEmployeeGroupName(user: User): string {
    const userProfile = this.props.userProfileArray.find((p) => p.user === user.url);
    const employeeGroupURL = userProfile ? userProfile.employeeGroup : null;
    const employeeGroup =
      typeof employeeGroupURL === "string"
        ? this.props.employeeGroupLookup(employeeGroupURL)
        : employeeGroupURL;
    const employeeGroupName =
      employeeGroup && this.props.employeeGroupLookup(employeeGroup.url)?.active
        ? employeeGroup.name
        : null;
    return employeeGroupName || "";
  }
  @bind
  handleCalendarScroll(): void {
    const scrollNode = this._calendarScrollContainerRef.current;
    if (scrollNode) {
      const usersHeaderNode = this._usersHeaderRef.current;
      const groupHeaderNode = this._groupHeaderRef.current;
      const hoursColumnNode = this._hoursColumnRef.current;
      if (usersHeaderNode) {
        usersHeaderNode.scrollLeft = scrollNode.scrollLeft;
      }
      if (groupHeaderNode) {
        groupHeaderNode.scrollLeft = scrollNode.scrollLeft;
      }
      if (hoursColumnNode) {
        hoursColumnNode.scrollTop = scrollNode.scrollTop;
      }
      this.updateScrollQuery(scrollNode.scrollLeft, scrollNode.scrollTop);
    }
  }
  @bind
  handleCloseTaskInfo(): void {
    this.setState({taskInfo: null});
  }
  @bind
  handleGoToOrder(_event: unknown, orderURL: string): void {
    const orderId = urlToId(orderURL);
    this.props.go("/order/:id", {id: orderId});
  }
  @bind
  handleRequestTaskInfo(position: {x: number; y: number}, task: Task): void {
    this.setState({taskInfo: {position, task}});
  }
  @bind
  handleTouchEnd(_event: TouchEvent): void {
    window.clearTimeout(this._scrollTimeout);
    window.clearTimeout(this._horizontalScrollTimeout);
  }
  @bind
  handleTouchMove(event: TouchEvent): void {
    const scrollableElement = this._calendarScrollContainerRef.current;
    if ((event.touches[0].target as HTMLDivElement).className === "gridBlock") {
      return;
    }
    window.clearTimeout(this._scrollTimeout);
    window.clearTimeout(this._horizontalScrollTimeout);
    if (scrollableElement) {
      const doScroll = (step: number): void => {
        scrollableElement.scrollTop = scrollableElement.scrollTop + step;
        this._scrollTimeout = window.setTimeout(doScroll.bind(null, step), SCROLL_DELAY_MS);
      };
      const doScrollHorizontal = (step: number): void => {
        scrollableElement.scrollLeft = scrollableElement.scrollLeft + step;
        this._horizontalScrollTimeout = window.setTimeout(
          doScrollHorizontal.bind(null, step),
          SCROLL_DELAY_MS,
        );
      };

      const scrollableElementRects = scrollableElement.getClientRects();

      if (event.touches[0].clientY < scrollableElementRects[0].top + SCROLL_ZONE_HEIGHT) {
        const scrollPixels = _.round(
          (event.touches[0].clientY - (scrollableElementRects[0].top + SCROLL_ZONE_HEIGHT)) /
            SCROLL_PIXELS_ADJUSTER,
        );

        doScroll(scrollPixels);
      }

      if (event.touches[0].clientY > scrollableElementRects[0].bottom - SCROLL_ZONE_HEIGHT) {
        const scrollPixels = _.round(
          (SCROLL_ZONE_HEIGHT - (scrollableElementRects[0].bottom - event.touches[0].clientY)) /
            SCROLL_PIXELS_ADJUSTER,
        );
        doScroll(scrollPixels);
      }

      if (event.touches[0].clientX < scrollableElementRects[0].left + SCROLL_ZONE_HEIGHT) {
        const scrollPixels = _.round(
          (event.touches[0].clientX - (scrollableElementRects[0].left + SCROLL_ZONE_HEIGHT)) /
            SCROLL_PIXELS_ADJUSTER,
        );

        doScrollHorizontal(scrollPixels);
      }

      if (event.touches[0].clientX > scrollableElementRects[0].right - SCROLL_ZONE_HEIGHT) {
        const scrollPixels = _.round(
          (SCROLL_ZONE_HEIGHT - (scrollableElementRects[0].right - event.touches[0].clientX)) /
            SCROLL_PIXELS_ADJUSTER,
        );
        doScrollHorizontal(scrollPixels);
      }
    }
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {currentUserURL, customerSettings, theme} = this.props;
    const {
      daysAbsenceArray,
      dndMode,
      filterString,
      hoursAbsenceArray,
      ordering,
      selectedDate,
      selectedDepartmentIdentifierSet,
      selectedEmployeeGroupUrlSet,
      selectedWorkTypeURLSet,
      unitLookup,
      userIsManager,
      userIsSeniorMachineOperator,
      userUserProfileLookup,
    } = this.props;
    const {startOfDay: startOfToday, startOfNextDay} = getMidnights(selectedDate);

    const calendarEndTimeDate = new Date(startOfNextDay);

    calendarEndTimeDate.setHours(8);
    const calendarEndTime = calendarEndTimeDate.toISOString();
    const calendarFromTimestamp = new ImmutableDate(startOfToday);
    const calendarToTimestamp = new ImmutableDate(calendarEndTime);
    const {absentEmployeeURLSet, daysAbsenceIntersectingDayList, hoursAbsenceIntersectingDayList} =
      getAbsenceLists(
        customerSettings,
        selectedDate,
        calendarFromTimestamp.toISOString(),
        calendarToTimestamp.toISOString(),
        daysAbsenceArray,
        hoursAbsenceArray,
      );

    let taskSeq = this.props.taskArray;

    if (selectedWorkTypeURLSet.size) {
      taskSeq = taskSeq.filter(
        (task) => task.workType && selectedWorkTypeURLSet.has(task.workType),
      );
    }
    if (selectedDepartmentIdentifierSet.size) {
      taskSeq = taskSeq.filter(
        (task) => task.department && selectedDepartmentIdentifierSet.has(task.department),
      );
    }
    if (customerSettings.orderDrafts) {
      taskSeq = taskSeq.filter((task) => {
        const orderURL = task.order;
        const order = orderURL && this.props.orderLookup(orderURL);
        return !orderURL || (order && !order.draft);
      });
    }

    const timerStartSeq = this.props.timerStartArray;
    const roleSeq = this.props.roleArray;

    const {
      incompleteTasks,
      intersectingCompletedTasks,
      intersectingPlannedTasks,
      sortedTimerStarts,
    } = tasksForPeriod(
      startOfToday,
      calendarEndTime,
      taskSeq,
      timerStartSeq,
      roleSeq,
      customerSettings.onlyManagerCreatedTasksCalendarPlanned,
    );
    let filteredIntersectingPlannedTasks = intersectingPlannedTasks;
    let tasksFromOverflow: {overflowMinutes: number; task: Task}[] | undefined;
    if (customerSettings.useCalendarWorkHours) {
      const tasksFromOverflowPresent = tasksOverflowingToDate(
        selectedDate,
        taskSeq,
        roleSeq,
        this.props.calendarWorkHoursArray,
        customerSettings.onlyManagerCreatedTasksCalendarPlanned,
      );
      tasksFromOverflow = tasksFromOverflowPresent;
      filteredIntersectingPlannedTasks = filteredIntersectingPlannedTasks.filter((task) => {
        const {url} = task;
        return tasksFromOverflowPresent.every((overflowTask) => overflowTask.task.url !== url);
      });
    }

    const tasksUserURLSet = new Set<string | null>();
    filteredIntersectingPlannedTasks.forEach((task) => {
      tasksUserURLSet.add(task.machineOperator);
    });
    intersectingCompletedTasks.forEach((task) => {
      tasksUserURLSet.add(task.machineOperator);
    });
    incompleteTasks.forEach((task) => {
      if (task.date === selectedDate) {
        tasksUserURLSet.add(task.machineOperator);
      }
    });

    const userLastTimerStarts: {[userURL: string]: TimerStart | undefined} = {};
    let filteredSortedTimerStarts = sortedTimerStarts;
    if (selectedWorkTypeURLSet.size) {
      const {taskLookup} = this.props;
      filteredSortedTimerStarts = filteredSortedTimerStarts.filter((timerStart) => {
        const taskURL = timerStart.task;
        const task = taskLookup(taskURL);
        return task && task.workType && selectedWorkTypeURLSet.has(task.workType);
      });
    }

    if (selectedDepartmentIdentifierSet.size) {
      const {taskLookup} = this.props;
      filteredSortedTimerStarts = filteredSortedTimerStarts.filter((timerStart) => {
        const taskURL = timerStart.task;
        const task = taskLookup(taskURL);
        return task && task.department && selectedDepartmentIdentifierSet.has(task.department);
      });
    }

    filteredSortedTimerStarts.forEach((timerStart) => {
      // consider only "start"
      if (timerStart.timer) {
        const employeeURL = timerStart.employee;
        userLastTimerStarts[employeeURL] = timerStart;
      }
    });
    filteredSortedTimerStarts.forEach((timerStart) => {
      // consider only "stop"
      if (!timerStart.timer) {
        const employeeURL = timerStart.employee;
        const lastTimerStart = userLastTimerStarts[employeeURL];
        if (
          lastTimerStart &&
          lastTimerStart.timer &&
          getNormalisedDeviceTimestamp(lastTimerStart) < getNormalisedDeviceTimestamp(timerStart)
        ) {
          userLastTimerStarts[employeeURL] = timerStart;
        }
      }
    });
    let usersMarkedAvailable: Set<string> | undefined;
    if (customerSettings.availabilityWeekdays || customerSettings.availabilityWeekends) {
      const selectedDateDate = dateFromString(selectedDate) as Date;
      console.assert(selectedDateDate);
      const weekdayNumber = selectedDateDate.getDay();

      const weekdayIsWeekend =
        weekdayNumber === WEEKDAY_SUNDAY || weekdayNumber === WEEKDAY_SATURDAY;
      if (
        (weekdayIsWeekend && customerSettings.availabilityWeekends) ||
        (!weekdayIsWeekend && customerSettings.availabilityWeekdays)
      ) {
        const weekData = weekNumber(selectedDateDate);
        const selectedWeek = weekData.week;
        const selecedYear = weekData.year;
        const weekday = WEEKDAYS[selectedDateDate.getDay()];
        usersMarkedAvailable = new Set(
          this.props.availabilityArray
            .filter(
              (availability) =>
                availability.weekNumber === selectedWeek &&
                availability.year === selecedYear &&
                availability[weekday],
            )
            .map((value) => value.user),
        );
      }
    }

    const userRoles: {[userURL: string]: Role | undefined} = {};
    this.props.roleArray.forEach((role) => {
      userRoles[role.user] = role;
    });

    const regularEmployeeURLs = new Set(
      this.props.userProfileArray.filter((p) => p.alwaysVisible).map((p) => p.user),
    );
    const selectedDateDate = dateFromString(selectedDate) as Date;
    console.assert(selectedDateDate);

    const userAnniversaryMap = customerSettings.anniversariesEnabled
      ? getUserAnniversaryMap(
          this.props.userProfileArray,
          this.props.anniversaryTypeArray,
          selectedDateDate,
        )
      : null;

    let usersWithAccommodationAllowanceList: string[] = [];
    if (userIsManager) {
      usersWithAccommodationAllowanceList = this.props.accomodationAllowanceArray
        .filter((accommodationAllowance) => accommodationAllowance.date === selectedDate)
        .map((accommodationAllowance) => accommodationAllowance.employee);
    }

    const filtering = !!(selectedWorkTypeURLSet.size || selectedDepartmentIdentifierSet.size);
    const selectedDateIsTodayOrPast = selectedDate <= dateToString(new Date());
    const unfilteredUsersWithTasksToday = _.sortBy(
      this.props.userArray.filter((user) => {
        const role = userRoles[user.url];
        if (role && role.consultant) {
          return false;
        }
        const userURL = user.url;
        if (
          customerSettings.machineOperatorsCanOnlySeeThemselvesOnCalendar &&
          !userIsManager &&
          userURL !== this.props.currentUserURL
        ) {
          return false;
        }
        if (tasksUserURLSet.has(userURL)) {
          return true;
        }
        if (
          tasksFromOverflow &&
          tasksFromOverflow.some((overflowTask) => overflowTask.task.machineOperator === userURL)
        ) {
          return true;
        }
        const lastTimerStart = userLastTimerStarts[userURL];
        const lastTimerStartDeviceTimestamp =
          lastTimerStart && getNormalisedDeviceTimestamp(lastTimerStart);
        if (
          lastTimerStartDeviceTimestamp &&
          ((lastTimerStartDeviceTimestamp > startOfToday &&
            lastTimerStartDeviceTimestamp < calendarEndTime) ||
            (lastTimerStart?.timer &&
              lastTimerStartDeviceTimestamp < calendarEndTime &&
              selectedDateIsTodayOrPast))
        ) {
          // user has some timer registration
          return true;
        }
        if (filtering && !tasksUserURLSet.has(userURL)) {
          return false;
        }
        if (usersMarkedAvailable && usersMarkedAvailable.has(userURL)) {
          return true;
        }
        if (regularEmployeeURLs.has(userURL) && user.active) {
          return true;
        }
        if (absentEmployeeURLSet.has(userURL)) {
          return true;
        }
        if (user.active && userAnniversaryMap && userAnniversaryMap.get(userURL)) {
          return true;
        }
        // NOTE: We *could* handle manually entered times/corrections on
        // incomplete tasks --- but probably not worth the complexity/computation
        // cost...
        return false;
      }),
      (user) => userUserProfileLookup(user.url)?.alias || user.loginIdentifier,
    );
    let usersWithTasksToday;
    if (ordering) {
      // sort by ordering, but push missing to the end
      const orderIndex = (user: User): number => {
        const index = ordering.indexOf(
          userUserProfileLookup(user.url)?.alias || user.loginIdentifier,
        );
        if (index !== -1) {
          return index;
        } else {
          return ordering.length;
        }
      };
      usersWithTasksToday = _.sortBy(unfilteredUsersWithTasksToday, orderIndex);
    } else {
      usersWithTasksToday = unfilteredUsersWithTasksToday;
    }

    if (customerSettings.defaultTaskEmployee) {
      const defaultTaskEmployeeUrl = instanceURL("user", customerSettings.defaultTaskEmployee);
      usersWithTasksToday = _.orderBy(
        usersWithTasksToday,
        (user) => user.url !== defaultTaskEmployeeUrl,
      );
    }
    if (
      customerSettings.useEmployeeGroups &&
      customerSettings.enableEmployeeGroups &&
      !dndMode &&
      !ordering
    ) {
      usersWithTasksToday = _.sortBy(
        unfilteredUsersWithTasksToday,
        (user) => this.getEmployeeGroupName(user) || null,
      );
      if (selectedEmployeeGroupUrlSet.size) {
        usersWithTasksToday = usersWithTasksToday.filter((user) => {
          const employeeGroupUrl = defaultToEmptyString(
            userUserProfileLookup(user.url)?.employeeGroup,
          );
          return selectedEmployeeGroupUrlSet.has(employeeGroupUrl);
        });
      }
    }

    if (filterString.trim()) {
      const initialsArray = filterString
        .trim()
        .split(/ +/)
        .map((x) => x.toUpperCase());
      usersWithTasksToday = usersWithTasksToday.filter((user) =>
        initialsArray.includes(
          (userUserProfileLookup(user.url)?.alias || user.loginIdentifier).toUpperCase(),
        ),
      );
    }

    let employeeGroupHeader;

    if (
      !ordering &&
      customerSettings.useEmployeeGroups &&
      customerSettings.enableEmployeeGroups &&
      !dndMode
    ) {
      const groups = new Map<string, number>();

      usersWithTasksToday.forEach((user) => {
        const groupName = this.getEmployeeGroupName(user);
        groups.set(groupName, (groups.get(groupName) || 0) + 1);
      });

      if ((groups.get("") || 0) < usersWithTasksToday.length) {
        employeeGroupHeader = [];

        const userColumnWidth = USER_COLUMN_WIDTH;
        const backgroundColor = grey[300];
        let withBackground = true;
        groups.forEach((count, name) => {
          const style: React.CSSProperties = {
            display: "inline-block",
            textAlign: "center",
            width: userColumnWidth * count,
          };
          if (withBackground) {
            style.backgroundColor = backgroundColor;
            style.color = theme.palette.getContrastText(backgroundColor);
          }
          const innerStyle: React.CSSProperties = {
            height: 24,
            overflow: "hidden",
            textOverflow: "ellipsis",
          };
          withBackground = !withBackground;
          employeeGroupHeader.push(
            <div key={name} style={style}>
              <div style={innerStyle}>{name || " "}</div>
            </div>,
          );
        });
      }
    }

    let workshopWorkTypeURLSet: Set<string> | undefined;
    if (customerSettings.hideUnscheduledWorkshopTasks) {
      const workshopWorkTypeIdentifierSet = customerSettings.workshopWorkTypes;
      workshopWorkTypeURLSet = new Set(
        this.props.workTypeArray
          .filter((w) => workshopWorkTypeIdentifierSet.includes(w.identifier))
          .map((w) => w.url),
      );
    }

    let tasksWithoutDateOrUser: Task[];
    if (
      customerSettings.enableUnscheduledCalendar &&
      (userIsManager ||
        userIsSeniorMachineOperator ||
        !customerSettings.hideUnscheduledCalendarForMachineOperators)
    ) {
      tasksWithoutDateOrUser = taskSeq.filter(
        (task) => (!task.date && !task.workFromTimestamp) || !task.machineOperator,
      );
      if (!userIsManager && !userIsSeniorMachineOperator) {
        const machineOperatorHideTasksAfterDate =
          machineOperatorCanSeeFutureTasksUntil(customerSettings);
        tasksWithoutDateOrUser = tasksWithoutDateOrUser.filter((task) => {
          const taskDate = task.date;
          return (
            !taskDate ||
            taskDate <= machineOperatorHideTasksAfterDate ||
            task.createdBy === currentUserURL
          );
        });
      }
    } else {
      tasksWithoutDateOrUser = [];
    }
    let partiallyPlannedTaskBlocks: React.JSX.Element[] = [];
    if (customerSettings.showUnscheduledInternalTaskCalendar) {
      partiallyPlannedTaskBlocks = tasksWithoutDateOrUser
        .filter(
          (t) =>
            !t.order &&
            (!workshopWorkTypeURLSet || !t.workType || !workshopWorkTypeURLSet.has(t.workType)),
        )
        .map((task) => {
          const style: React.CSSProperties = {
            display: "inline-block",
            height: PARTIALLY_PLANNED_TASK_HEIGHT,
            margin: "0px 4px 0px",
            position: "relative",

            width: USER_COLUMN_WIDTH - 8,
          };
          return (
            <DraggableTaskBlock
              blockType="partiallyPlanned"
              containerStyle={style}
              customerSettings={this.props.customerSettings}
              go={this.props.go}
              key={task.url}
              onRequestTaskInfo={this.handleRequestTaskInfo}
              {...this.resolveTaskRelations(task)}
              unitLookup={unitLookup}
            />
          );
        });
    }
    const todoBlocks = _.sortBy(
      tasksWithoutDateOrUser.filter((t) => t.order),
      (task) => {
        const workTypeURL = task.workType;
        const workType = workTypeURL ? this.props.workTypeLookup(workTypeURL) : null;
        const workTypeIdentifier = workType ? workType.identifier : "";
        const {date} = task;
        return [!date, date, workTypeIdentifier];
      },
    ).map((task) => {
      const style: React.CSSProperties = {
        display: "inline-block",
        height: PARTIALLY_PLANNED_TASK_HEIGHT,
        margin: "0px 4px 0px",
        position: "relative",

        width: USER_COLUMN_WIDTH - 8,
      };
      return (
        <DraggableTaskBlock
          blockType="partiallyPlanned"
          containerStyle={style}
          customerSettings={this.props.customerSettings}
          go={this.props.go}
          key={task.url}
          onRequestTaskInfo={this.handleRequestTaskInfo}
          {...this.resolveTaskRelations(task)}
          unitLookup={unitLookup}
        />
      );
    });

    const todoBlocksHeight = todoBlocks.length ? 30 : 0;
    let tasksWithoutDateOrUserHeader;
    if (tasksWithoutDateOrUser.length) {
      tasksWithoutDateOrUserHeader = (
        <h4 style={{paddingLeft: 2}}>
          {customerSettings.employeeLabelVariant === "MACHINEOPERATOR"
            ? formatMessage(messages.tasksWithoutDateMachineOperator)
            : customerSettings.employeeLabelVariant === "EMPLOYEE"
              ? formatMessage(messages.tasksWithoutDateEmployee)
              : formatMessage(messages.tasksWithoutDateChauffeur)}
        </h4>
      );
    }
    const userColumnWidth = USER_COLUMN_WIDTH;
    const partiallyPlannedTaskBlocksHeight = partiallyPlannedTaskBlocks.length ? 30 : 0;
    const userURLs = usersWithTasksToday.map((user) => user.url);
    const usersHeader = (
      <UsersHeaderBlock
        dndMode={dndMode}
        fromTimestamp={calendarFromTimestamp}
        incompleteTasks={incompleteTasks}
        intersectingCompletedTasks={intersectingCompletedTasks}
        intersectingPlannedTasks={filteredIntersectingPlannedTasks}
        onRequestOrdering={this.props.onRequestOrdering}
        ordering={ordering}
        selectedDateString={selectedDate}
        sortedTimerStarts={filteredSortedTimerStarts}
        toTimestamp={calendarToTimestamp}
        userAnniversaryMap={userAnniversaryMap}
        usersWithAccommodationAllowanceList={usersWithAccommodationAllowanceList}
        usersWithTasksToday={unfilteredUsersWithTasksToday}
        visibleUsersWithTasksToday={usersWithTasksToday}
      />
    );
    let noUserTasks;
    if (!usersWithTasksToday.length && !filterString.trim()) {
      noUserTasks = <span id="no-user-tasks-line">{formatMessage(messages.noUsersWithTasks)}</span>;
    }
    let longestUserTaskWithoutTimeList = 0;
    let userTasksWithoutTime: React.JSX.Element[] = [];
    if (filteredIntersectingPlannedTasks.length) {
      const now = new Date();
      const nowString = now.toISOString();
      const taskTimerStartMapping = generateTimerStartMapping(sortedTimerStarts);
      const {
        contactLookup,
        cultureLookup,
        customerLookup,
        locationLookup,
        machineLookup,
        orderLookup,
        priceItemLookup,
        productLookup,
        projectLookup,
        timerLookup,
        workTypeLookup,
      } = this.props;
      const boundResolveTaskRelations = (task: Task): TaskWithRelations => {
        let taskWithComputedTimeSet = task;
        if (!task.archivable) {
          const taskURL = task.url;
          const arrayForTask = taskTimerStartMapping[taskURL];
          const computedIntervals = computeIntervalsTruncated(
            arrayForTask || [],
            now.toISOString(),
          );
          taskWithComputedTimeSet = {
            ...task,
            computedTimeSet: computedIntervals,
          };
        }
        return resolveTaskRelations(
          taskWithComputedTimeSet,
          calendarFromTimestamp,
          calendarToTimestamp,
          nowString,
          false,
          this.props.customerSettings,
          {
            contactLookup,
            cultureLookup,
            customerLookup,
            locationLookup,
            machineLookup,
            orderLookup,
            priceItemLookup,
            productLookup,
            projectLookup,
            timerLookup,
            workTypeLookup,
          },
        );
      };
      userTasksWithoutTime = usersWithTasksToday.map((user) => {
        const userURL = user.url;
        const columns = sortTasksInColumns(
          userURL,
          intersectingPlannedTasks,
          intersectingCompletedTasks,
          incompleteTasks,
          boundResolveTaskRelations,
          this.props.customerSettings.taskOverlapWarningAfterMinutes,
        );

        const columnCount = columns.length;
        const userColumnStyle = {
          display: "inline-block",
          maxWidth: userColumnWidth * (columnCount || 1),
          verticalAlign: "bottom",
          width: userColumnWidth * (columnCount || 1),
        };
        const taskList = filteredIntersectingPlannedTasks
          .filter(
            (task) =>
              task.machineOperator === user.url &&
              task.date === selectedDate &&
              !task.time &&
              !task.workFromTimestamp,
          )
          .map((task) => {
            const style: React.CSSProperties = {
              height: PARTIALLY_PLANNED_TASK_HEIGHT,
              margin: "2px 4px 2px",
              position: "relative",

              width: USER_COLUMN_WIDTH - 8,
            };
            return (
              <DraggableTaskBlock
                blockType="planned"
                containerStyle={style}
                customerSettings={this.props.customerSettings}
                go={this.props.go}
                key={task.url}
                onRequestTaskInfo={this.handleRequestTaskInfo}
                {...this.resolveTaskRelations(task)}
                unitLookup={unitLookup}
              />
            );
          });
        longestUserTaskWithoutTimeList = Math.max(longestUserTaskWithoutTimeList, taskList.length);
        return (
          <div key={`without-time-${userURL}`} style={userColumnStyle}>
            {taskList}
          </div>
        );
      });
    }
    const userTasksWithoutTimeHeight =
      PARTIALLY_PLANNED_TASK_HEIGHT * longestUserTaskWithoutTimeList +
      longestUserTaskWithoutTimeList * 4;
    const partiallyPlannedAndTodoBlocksHeight =
      partiallyPlannedTaskBlocks.length === 0 && todoBlocks.length === 0
        ? 0
        : partiallyPlannedTaskBlocksHeight + todoBlocksHeight + 50;
    let partiallyPlannedAndTodoLine;
    if (partiallyPlannedTaskBlocks.length || todoBlocks.length) {
      const partiallyPlannedAndTodoLineStyle: React.CSSProperties = {
        borderColor: grey[300],
        borderStyle: "solid",
        borderWidth: "0px 0px 1px 0px",
        height: partiallyPlannedAndTodoBlocksHeight,
        overflowX: "auto",
        paddingLeft: 2,
        width: "100%",
      };
      let partiallyPlannedTaskLine: React.JSX.Element | undefined;
      if (partiallyPlannedTaskBlocks.length) {
        const partiallyPlannedTaskLineStyle: React.CSSProperties = {
          height: partiallyPlannedTaskBlocksHeight,
          marginBottom: 4,
          verticalAlign: "top",
          whiteSpace: "nowrap",
        };
        partiallyPlannedTaskLine = (
          <div style={partiallyPlannedTaskLineStyle}>{partiallyPlannedTaskBlocks}</div>
        );
      }
      let todoLine: React.JSX.Element | undefined;
      if (todoBlocks.length) {
        const todoLineStyle: React.CSSProperties = {
          height: todoBlocksHeight,
          verticalAlign: "top",
          whiteSpace: "nowrap",
        };
        todoLine = <div style={todoLineStyle}>{todoBlocks}</div>;
      }
      partiallyPlannedAndTodoLine = (
        <PlannedLineDrop style={partiallyPlannedAndTodoLineStyle}>
          <div id="partially-planned-and-todo-line">
            {tasksWithoutDateOrUserHeader}
            {partiallyPlannedTaskLine}
            {todoLine}
          </div>
        </PlannedLineDrop>
      );
    }
    const groupHeaderStyle: React.CSSProperties = {
      borderColor: grey[300],
      borderStyle: "solid",
      borderWidth: "0px 0px 0px 1px",

      height: employeeGroupHeader ? 24 : 0,
      marginLeft: HOUR_COLUMN_WIDTH - 1,
      overflow: "hidden",
      paddingRight: 15,
      whiteSpace: "nowrap",
      width: `calc(100% - ${HOUR_COLUMN_WIDTH}px)`,
    };

    const headerLineHeight = 24;
    const headerLines =
      (customerSettings.calendarShowEmployeeAlias ? 1 : 0) +
      (customerSettings.calendarShowEmployeeNumber ? 1 : 0) +
      (customerSettings.calendarShowEmployeeName ? 2 : 0);

    const usersHeaderHeight = Math.max(headerLines, 1) * headerLineHeight;

    const usersHeaderStyle: React.CSSProperties = {
      height: usersHeaderHeight,
      marginLeft: HOUR_COLUMN_WIDTH,
      overflow: "hidden",
      paddingRight: 15,
      whiteSpace: "nowrap",
      width: `calc(100% - ${HOUR_COLUMN_WIDTH}px)`,
    };
    const weekHeaderStyle: React.CSSProperties = {
      borderColor: grey[300],
      borderStyle: "solid",
      borderWidth: "0px 1px 0px 0px",
      height: usersHeaderHeight,
      position: "absolute",
      textAlign: "center",
      width: HOUR_COLUMN_WIDTH,
    };
    let userTasksWithoutTimeBlock: React.JSX.Element | undefined;
    if (userTasksWithoutTime.length) {
      const style: React.CSSProperties = {
        borderColor: grey[300],
        borderStyle: "solid",
        borderWidth: "0px 0px 0px 0px",
        height: userTasksWithoutTimeHeight,
        lineHeight: "16px",
        whiteSpace: "nowrap",
      };
      userTasksWithoutTimeBlock = <div style={style}>{userTasksWithoutTime}</div>;
    }
    let filteringBlock: React.JSX.Element | undefined;

    if (filtering || selectedEmployeeGroupUrlSet.size) {
      filteringBlock = (
        <FilterBar
          onRequestFilterClear={this.props.onRequestFilterClear}
          selectedDepartmentIdentifierSet={selectedDepartmentIdentifierSet}
          selectedEmployeeGroupURLSet={selectedEmployeeGroupUrlSet}
          selectedWorkTypeURLSet={selectedWorkTypeURLSet}
        />
      );
    }
    let manualOrderBlock;
    let manualOrderBlockHeight = 0;
    if (ordering || dndMode) {
      manualOrderBlockHeight = 24;
      const style: React.CSSProperties = {
        backgroundColor: grey[400],
        color: theme.palette.getContrastText(grey[400]),
        height: manualOrderBlockHeight,
        overflow: "hidden",
        paddingLeft: 2,

        paddingRight: 2 + 24 + 2,
        position: "relative",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      };
      manualOrderBlock = (
        <div id="manual-order-block" style={style}>
          <FormattedMessage
            defaultMessage="Manuel kolonnesortering"
            id="task-calendar.label.manual-ordering"
          />
        </div>
      );
    }
    const mainContainerStyle: React.CSSProperties = {
      height: `calc(100% - ${
        partiallyPlannedAndTodoBlocksHeight +
        usersHeaderHeight +
        (employeeGroupHeader ? headerLineHeight : 0) +
        (filtering ? FILTER_BAR_HEIGHT : 0) +
        manualOrderBlockHeight
      }px)`,
      paddingLeft: HOUR_COLUMN_WIDTH,
      position: "relative",
      width: "100%",
    };

    let content = (
      <div ref={this._calendarContainerRef} style={{height: "100%"}}>
        {filteringBlock}
        {partiallyPlannedAndTodoLine}
        {manualOrderBlock}
        <div id="group-header" ref={this._groupHeaderRef} style={groupHeaderStyle}>
          {employeeGroupHeader}
        </div>

        <div style={{position: "relative"}}>
          <span id="week-header" style={weekHeaderStyle}>
            <span style={{bottom: 0, position: "absolute"}}>
              {weekNumber(selectedDateDate).week}
            </span>
          </span>
          <div id="users-header" ref={this._usersHeaderRef} style={usersHeaderStyle}>
            {usersHeader}
            {noUserTasks}
          </div>
        </div>

        <div style={mainContainerStyle}>
          <div
            ref={this._hoursColumnRef}
            style={{
              height: "100%",
              marginLeft: -HOUR_COLUMN_WIDTH,
              overflow: "hidden",
              paddingBottom: 15,
              position: "absolute",
            }}
          >
            <div
              style={{
                borderColor: grey[300],
                borderStyle: "solid",
                borderWidth: "0px 1px 0px 0px",
                height: userTasksWithoutTimeHeight,
                width: HOUR_COLUMN_WIDTH,
              }}
            />
            <HoursColumn
              dateTransitionMark
              fromTimestamp={calendarFromTimestamp}
              toTimestamp={calendarToTimestamp}
            />
          </div>
          <div
            className="scrollable"
            ref={this._calendarScrollContainerRef}
            style={{height: "100%", overflow: "auto", width: "100%"}}
          >
            <div
              style={{
                height: "100%",
                width: userURLs.length * userColumnWidth,
              }}
            >
              <div style={{height: "100%"}}>
                <div style={{height: "100%", width: "100%"}}>
                  {userTasksWithoutTimeBlock}
                  <div>
                    <Calendar
                      dateTransitionMark
                      fromTimestamp={calendarFromTimestamp}
                      hideHoursColumn
                      incompleteTasks={incompleteTasks}
                      intersectingCompletedTasks={intersectingCompletedTasks}
                      intersectingDaysAbsenceList={daysAbsenceIntersectingDayList}
                      intersectingHoursAbsenceList={hoursAbsenceIntersectingDayList}
                      intersectingPlannedTasks={filteredIntersectingPlannedTasks}
                      nowMinutes={this.props.nowMinutes}
                      onAbsenceClick={this.props.onAbsenceClick}
                      onRequestCloseTaskInfo={
                        this.state.taskInfo ? this.handleCloseTaskInfo : undefined
                      }
                      onRequestTaskInfo={this.handleRequestTaskInfo}
                      sortedTimerStarts={filteredSortedTimerStarts}
                      tasksFromOverflow={tasksFromOverflow}
                      toTimestamp={calendarToTimestamp}
                      userAnniversaryMap={userAnniversaryMap}
                      userURLs={userURLs}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (!bowser.mobile && !bowser.tablet) {
      content = (
        <ClickAwayListener onClickAway={this.handleCloseTaskInfo}>{content}</ClickAwayListener>
      );
    }

    return (
      <>
        {content}
        <TaskInfoPopOver
          onClose={this.handleCloseTaskInfo}
          position={this.state.taskInfo?.position || null}
          task={this.state.taskInfo?.task || null}
        />
        <TaskDragPreview />
      </>
    );
  }

  resolveTaskRelations(task: Task): TaskWithRelations {
    /*
    const now = new Date();
    let taskWithComputedTimeSet = task;
      if (!task.archivable) {
        const taskURL = task.url;
        const arrayForTask = taskTimerStartMapping[taskURL];
        const computedIntervals = computeIntervalsTruncated(
          arrayForTask || [],
          now.toISOString(),
        );
        taskWithComputedTimeSet = {...task, computedTimeSet: computedIntervals};
      }
      */
    const {
      contactLookup,
      cultureLookup,
      customerLookup,
      customerSettings,
      locationLookup,
      machineLookup,
      orderLookup,
      priceItemLookup,
      productLookup,
      projectLookup,
      routePlanLookup,
      timerLookup,
      workTypeLookup,
    } = this.props;
    const now = new Date();
    return resolveTaskRelations(task, null, null, now.toISOString(), false, customerSettings, {
      contactLookup,
      cultureLookup,
      customerLookup,
      locationLookup,
      machineLookup,
      orderLookup,
      priceItemLookup,
      productLookup,
      projectLookup,
      routePlanLookup,
      timerLookup,
      workTypeLookup,
    });
  }

  scrollToNow(): void {
    const {nowMinutes} = this.props;
    if (nowMinutes == null) {
      return;
    }
    const today = dateToString(new Date());
    const node = this._calendarScrollContainerRef.current;
    if (!node || this.props.selectedDate !== today) {
      return;
    }
    const nowY = calculateMinutePosition(nowMinutes);

    node.scrollTop = Math.max(0, nowY - node.clientHeight / 2);
  }

  UNSAFE_componentWillMount(): void {
    const {selectedDate} = this.props;
    this.updateQuery(selectedDate);
  }
  UNSAFE_componentWillReceiveProps(nextProps: EmployeeTabContentProps): void {
    if (nextProps.selectedDate !== this.props.selectedDate) {
      this.updateQuery(nextProps.selectedDate);
    }
  }

  updateQuery(selectedDate: string): void {
    const timeBackDateDate = new Date();
    timeBackDateDate.setDate(timeBackDateDate.getDate() - ARCHIVE_DAYS);
    const timeBackDate = dateToString(timeBackDateDate);
    if (selectedDate <= timeBackDate) {
      // archive relevant --- completed/recorded tasks for this date may not
      // be present in normal working set
      const {pathName, temporaryQueriesRequestedForPath} = this.props;
      const selectedDateDate = dateFromString(selectedDate) as Date;
      const rangeStartString = startOfDay(selectedDateDate).toISOString();
      const rangeEndString = endOfDay(selectedDateDate).toISOString();
      const taskCheck: Check = {
        checks: [
          {
            checks: [
              {
                memberName: "workToTimestamp",
                type: "memberGt",
                value: rangeStartString,
              },
              {
                memberName: "workFromTimestamp",
                type: "memberLt",
                value: rangeEndString,
              },
              {memberName: "completed", type: "memberTruthy"},
            ],
            type: "and",
          },
          {
            checks: [
              {
                memberName: "date",
                type: "memberGte",
                value: dateToString(subDays(new Date(rangeStartString), WEEK_DAYS)),
              },
              {
                memberName: "date",
                type: "memberLte",
                value: selectedDate,
              },
            ],
            type: "and",
          },
          {memberName: "completed", type: "memberFalsy"},
        ],
        type: "or",
      };
      const orderCheck: Check = {
        check: taskCheck,
        fromResource: "task",
        memberName: "order",
        type: "targetOfForeignKey",
      };
      const relatedTaskCheck: Check = {
        check: taskCheck,
        memberName: "task",
        targetType: "task",
        type: "hasForeignKey",
      };
      const queries = [
        makeQuery({
          check: taskCheck,
          filter: {
            fromDate: selectedDate,
            toDate: selectedDate,
          },
          independentFetch: true,
          resourceName: "task",
        }),
        makeQuery({
          check: orderCheck,
          independentFetch: false,
          resourceName: "order",
        }),
        makeQuery({
          check: relatedTaskCheck,
          independentFetch: false,
          resourceName: "timerStart",
        }),
      ];
      if (this.props.customerSettings.showPhotoOnCalendar) {
        queries.push(
          makeQuery({
            check: relatedTaskCheck,
            independentFetch: false,
            resourceName: "taskPhoto",
          }),
        );
      }
      temporaryQueriesRequestedForPath(queries, pathName, TEMPORARY_QUERIES_KEY);
    }
  }
}

const ConnectedEmployeeTabContent: React.ComponentType<EmployeeTabContentOwnProps> = connect<
  EmployeeTabContentStateProps,
  EmployeeTabContentDispatchProps,
  EmployeeTabContentOwnProps,
  AppState
>(
  createStructuredSelector<AppState, EmployeeTabContentStateProps>({
    accomodationAllowanceArray: getAccomodationAllowanceArray,
    anniversaryTypeArray: getAnniversaryTypeArray,
    anniversaryTypeLookup: getAnniversaryTypeLookup,
    availabilityArray: getAvailabilityArray,
    calendarWorkHoursArray: getCalendarWorkHoursArray,
    contactLookup: getContactLookup,
    cultureLookup: getCultureLookup,
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    daysAbsenceArray: getDaysAbsenceArray,
    employeeGroupLookup: getEmployeeGroupLookup,
    hoursAbsenceArray: getHoursAbsenceArray,
    locationLookup: getLocationLookup,
    machineLookup: getFilteredMachineLookup,
    orderLookup: getOrderLookup,
    priceItemLookup: getPriceItemLookup,
    productLookup: getProductLookup,
    projectLookup: getProjectLookup,
    roleArray: getRoleArray,
    routePlanLookup: getRoutePlanLookup,
    taskArray: getTaskArray,
    taskLookup: getTaskLookup,
    timerLookup: getTimerLookup,
    timerStartArray: getTimerStartArray,
    unitLookup: getUnitLookup,
    userArray: getUserArray,
    userProfileArray: getUserProfileArray,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeArray: getWorkTypeArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    go: actions.go,
    putQueryKeys: actions.putQueryKeys,
  },
)(EmployeeTabContent);

export default withTheme(ConnectedEmployeeTabContent);
