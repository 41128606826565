import {ReadonlyConfig} from "@co-common-libs/config";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";

type ExtendedProductGroupSettingsPart = EconomySystemIntegrationKindPart &
  Pick<ReadonlyConfig, "canEditProductGroups">;

export interface ExtendedProductGroupSettings {
  canImport: boolean;
  canManage: boolean;
}

export function extendedProductGroupSettings(
  config: ExtendedProductGroupSettingsPart,
): ExtendedProductGroupSettings {
  const {canEditProductGroups} = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const integrationAllowsManage =
    economySystemIntegrationKind !== ActiveEconomySystemIntegrationKind.IMPORT_ONLY;

  const isImportOrLiveSyncIntegration =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.IMPORT_ONLY ||
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC;

  return {
    canImport: isImportOrLiveSyncIntegration && canEditProductGroups,
    canManage: integrationAllowsManage && canEditProductGroups,
  };
}
